import { useCallback } from 'react';
import {
  QuestionOption,
  REPORT_STATUS,
  Report,
  SURVEY_FREQUENCY,
  SURVEY_TYPE,
  Survey,
  SurveyQuestion,
} from '../../../types';
import { useNavigate, useParams } from 'react-router';
import { useCompanyById, useUpdateCompany } from '../../../queries/useCompanies';
import { DUE_DILIGENCE_SURVEY_NAME } from '../../../constants/constants';
import { SURVEYS, useCreateSurvey } from '../../../queries/useSurveys';
import { useCreateRequest } from '../../../queries/useRequests';
import { useQueryClient } from 'react-query';
import { ROUTES } from '../../../constants/routes';
import { REPORTS } from '../../../queries/useReports';

export const useInvestorDueDiligenceExclusionClick = () => {
  const params = useParams();
  const { data: company } = useCompanyById(Number(params.id));
  const updateCompany = useUpdateCompany();
  const createSurvey = useCreateSurvey();
  const createRequest = useCreateRequest();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const onInvestorExclusionsDueDiligenceClickHandler = useCallback(
    async (investorExclusionScreeningReport?: Report, exclusionScreeningSurvey?: Survey) => {
      const isExclusionsScreeningAvailable =
        investorExclusionScreeningReport?.status === REPORT_STATUS.IN_REVIEW ||
        investorExclusionScreeningReport?.status === REPORT_STATUS.APPROVED ||
        investorExclusionScreeningReport?.status === REPORT_STATUS.SUBMITTED;

      if (!isExclusionsScreeningAvailable || !company) return;

      if (!company.exclusionDueDiligenceSurvey || !investorExclusionScreeningReport) {
        const surveySections =
          exclusionScreeningSurvey?.sections.map((section) => {
            const questions: SurveyQuestion[] = [];
            section.questions.forEach((question) => {
              const response = investorExclusionScreeningReport?.response?.[question.id];
              if ((response as QuestionOption)?.name === 'Yes' && question.followUpQuestions) {
                questions.push(...question.followUpQuestions);
              }
            });
            return {
              ...section,
              questions,
            };
          }) || [];
        const filteredSections = surveySections.filter((section) => section.questions.length > 0);
        const survey = {
          name: DUE_DILIGENCE_SURVEY_NAME,
          isArchived: false,
          isAssigned: true,
          sections: filteredSections,
          type: SURVEY_TYPE.SYSTEM,
        };
        const createdSurvey = await createSurvey.mutateAsync(survey);

        await updateCompany.mutateAsync({
          id: company.id,
          exclusionDueDiligenceSurvey: createdSurvey.id,
        });

        createRequest.mutate(
          {
            companyId: company.id,
            frequency: SURVEY_FREQUENCY.ONE_TIME,
            surveyId: createdSurvey.id,
            name: DUE_DILIGENCE_SURVEY_NAME,
          },
          {
            onSuccess: (request) => {
              navigate(`/${ROUTES.QUESTIONNAIRES}/${request.reports[0].id}/progress`);
              queryClient.refetchQueries(`${REPORTS}-${request.company.id}`);
              queryClient.refetchQueries(SURVEYS);
            },
          }
        );
        return;
      }
    },
    [company, createRequest, createSurvey, navigate, queryClient, updateCompany]
  );

  return { onInvestorExclusionsDueDiligenceClickHandler };
};
