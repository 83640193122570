import { IconButton, styled, useTheme } from '@mui/material';
import { useParams } from 'react-router';
import { useCompanyById } from '../../../queries/useCompanies';
import { Typography } from '../../../components/Typography/Typography';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { useState } from 'react';
import { AnalystRatingModal } from './AnalystRatingModal/AnalystRatingModal';
import { EsgRiskIcon } from './EsgRiskIcon';
import { EsgOpportunitiesIcon } from './EsgOpportunitiesIcon';
import { GovernanceScoreIcon } from './GovernanceScoreIcon';

const Container = styled('div')`
  background-color: ${({ theme }) => theme.colors.primary[0]};
  border-radius: 2px;
  padding: 16px 24px;
  position: relative;
  min-height: 160px;
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
`;

const RatingItem = styled('div')`
  min-width: 120px;
`;

const RatingWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 26px;
`;

const RatingText = styled(Typography)`
  margin-left: 8px;
`;

const NotesItem = styled('div')`
  flex: 1;
`;

const EditCTA = styled(IconButton)`
  position: absolute;
  right: 16px;
  top: 16px;
  padding: 0;
  svg {
    width: 16px;
    height: 16px;
  }
`;

export const AnalystRating = () => {
  const params = useParams();
  const { data: company } = useCompanyById(Number(params.id));

  const [isAnalystModalOpen, setIsAnalystModalOpen] = useState(false);

  const { colors } = useTheme();

  return (
    <Container>
      <EditCTA onClick={() => setIsAnalystModalOpen(true)}>
        <EditIcon />
      </EditCTA>
      <RatingItem>
        <Typography variant='subtitle2' color={colors.primary[90]}>
          ESG Risk Level
        </Typography>
        <RatingWrapper>
          <EsgRiskIcon esgRiskLevel={company?.esgRiskLevel} />
          <RatingText variant='h3' color={colors.primary[90]}>
            {company?.esgRiskLevel}
          </RatingText>
        </RatingWrapper>
      </RatingItem>
      <RatingItem>
        <Typography variant='subtitle2' color={colors.primary[90]}>
          ESG Opportunities
        </Typography>
        <RatingWrapper>
          <EsgOpportunitiesIcon esgOpportunitiesLevel={company?.esgOpportunitiesLevel} />
          <RatingText variant='h3' color={colors.primary[90]}>
            {company?.esgOpportunitiesLevel}
          </RatingText>
        </RatingWrapper>
      </RatingItem>
      {company?.governanceScore && (
        <RatingItem>
          <Typography variant='subtitle2' color={colors.primary[90]}>
            EU Governance Score
          </Typography>
          <RatingWrapper>
            <GovernanceScoreIcon governanceScore={company?.governanceScore} />
            <RatingText variant='h3' color={colors.primary[90]}>
              {company?.governanceScore}
            </RatingText>
          </RatingWrapper>
        </RatingItem>
      )}
      {company?.analystRatingNotes && (
        <NotesItem>
          <Typography variant='subtitle2' color={colors.primary[90]} style={{ marginBottom: 20 }}>
            Notes
          </Typography>
          <Typography variant='body' color={colors.primary[90]}>
            {company?.analystRatingNotes}
          </Typography>
        </NotesItem>
      )}
      <AnalystRatingModal
        isOpen={isAnalystModalOpen}
        onClose={() => setIsAnalystModalOpen(false)}
        title='Edit Analyst Rating'
        confirmCTAText='Save Changes'
      />
    </Container>
  );
};
