import { ReactComponent as HighGreen } from '../../../assets/icons/high-green.svg';
import { ReactComponent as Medium } from '../../../assets/icons/medium.svg';
import { ReactComponent as LowRed } from '../../../assets/icons/low-red.svg';

interface Props {
  governanceScore?: number | null;
}

export const GovernanceScoreIcon = ({ governanceScore }: Props) => {
  if (!governanceScore) return null;

  if (governanceScore < 3) return <LowRed />;
  if (governanceScore >= 3 && governanceScore <= 5) return <Medium />;
  return <HighGreen />;
};
