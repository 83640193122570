import { Table } from '@tanstack/react-table';
import { Multiselect } from '../../../../../components/Multiselect/Multiselect';
import { Company, SelectItem } from '../../../../../types';
import { useCallback } from 'react';
import { useAtom } from 'jotai';
import { selectedCompanyCountriesFilterState } from '../../../../../state/UIState';

interface Props {
  table: Table<Company>;
  sortedUniqueCountries: SelectItem[];
}

export const CountriesFilter = ({ table, sortedUniqueCountries }: Props) => {
  const [selectedCountries, setSelectedCountries] = useAtom(selectedCompanyCountriesFilterState);

  const onChangeCountriesFilter = useCallback(
    (countries: SelectItem[]) => {
      setSelectedCountries(countries);
      table.getColumn('country')?.setFilterValue(countries.map((c) => c.value));
    },
    [setSelectedCountries, table]
  );
  return (
    <Multiselect
      style={{ width: '220px' }}
      options={sortedUniqueCountries}
      value={selectedCountries}
      onChange={(_, val) => {
        onChangeCountriesFilter(val as SelectItem[]);
      }}
      disablePortal
      optionsName='Countries'
      fieldPlaceholder='Filter by Countries'
    />
  );
};
