import { useParams } from 'react-router';
import { useReportsByCompanyId } from '../../../queries/useReports';
import { REPORT_STATUS, WorkflowStep } from '../../../types';
import { Task } from '../TaskGroup';
import { useOnReportClick } from '../../../hooks/useOnReportClick';
import { TaskReportIndicator } from '../TaskGroup/TaskReportIndicator';
import { useReportTaskClickHandler } from '../../../hooks/useReportTaskClickHandler';
import { useCompanyById } from '../../../queries/useCompanies';
import {
  DUE_DILIGENCE_SURVEY_NAME,
  INVESTOR_EXCLUSIONS_SURVEY_ID,
  SASB_ASSESSMENT_NAME,
} from '../../../constants/constants';
import { SASBStep } from './SASBStep';
import { ScreeningExclusionStep } from './ScreeningExclusionStep';
import { DueDiligenceExclusionStep } from './DueDiligenceExclusionStep';
import { useMemo } from 'react';
import { useReportStatusIndicator } from '../../../pages/SIngleCompany/hooks/useReportStatusIndicator';

interface Props {
  step: WorkflowStep;
  isBlocked?: boolean;
  hasAccess?: boolean;
  hasEvaluationAccess?: boolean;
  isStepCompleted?: boolean;
}

export const AssessmentStep = ({
  step,
  isBlocked,
  hasAccess,
  hasEvaluationAccess,
  isStepCompleted,
}: Props) => {
  const params = useParams();
  const { data: companyReports } = useReportsByCompanyId(Number(params.id));
  const { data: company } = useCompanyById(Number(params.id));

  const report = companyReports?.find((report) => report.survey.id === step?.survey?.id);
  const { onReportClick } = useOnReportClick();

  const reportStatus = useReportStatusIndicator(report);

  const { onReportTaskClickHandler } = useReportTaskClickHandler();

  const isInitialAccess = useMemo(() => {
    return (
      !report ||
      report?.status === REPORT_STATUS.NOT_STARTED ||
      report?.status === REPORT_STATUS.IN_PROGRESS ||
      report?.status === REPORT_STATUS.REJECTED ||
      report?.status === REPORT_STATUS.SUBMITTED
    );
  }, [report]);

  const isEvaluationAccess = useMemo(
    () => report?.status === REPORT_STATUS.IN_REVIEW,
    [report?.status]
  );

  const hasInitialAccess = isInitialAccess && hasAccess;

  const hasEvalAccess = isEvaluationAccess && hasEvaluationAccess && !isStepCompleted;

  if (step?.name === SASB_ASSESSMENT_NAME) {
    return (
      <SASBStep
        isBlocked={isBlocked}
        hasAccess={hasAccess}
        hasEvaluationAccess={hasEvaluationAccess}
        isStepCompleted={isStepCompleted}
      />
    );
  }

  if (step?.survey?.id === INVESTOR_EXCLUSIONS_SURVEY_ID) {
    return (
      <ScreeningExclusionStep
        isBlocked={isBlocked}
        hasAccess={hasAccess}
        hasEvaluationAccess={hasEvaluationAccess}
        isStepCompleted={isStepCompleted}
      />
    );
  }

  if (step?.name === DUE_DILIGENCE_SURVEY_NAME) {
    return (
      <DueDiligenceExclusionStep
        isBlocked={isBlocked}
        hasAccess={hasAccess}
        hasEvaluationAccess={hasEvaluationAccess}
        isStepCompleted={isStepCompleted}
      />
    );
  }

  const isDisabled = isBlocked || (!hasInitialAccess && !hasEvalAccess);

  return (
    <Task
      taskName={step?.name}
      onTaskNameClick={report ? () => onReportClick(report) : undefined}
      isHighlighted={
        (hasInitialAccess || hasEvalAccess) &&
        reportStatus !== REPORT_STATUS.APPROVED &&
        reportStatus !== REPORT_STATUS.SUBMITTED
      }
      disabled={isDisabled}
      infoText={isBlocked ? 'This step is blocked' : ''}
    >
      <TaskReportIndicator
        onCreateReport={() =>
          onReportTaskClickHandler(report, step?.survey?.id, step?.survey?.name, company?.id)
        }
        report={report}
        buttonText='Proceed to Assessment'
        disabled={isDisabled}
      />
    </Task>
  );
};
