import { useCallback, useMemo } from 'react';
import { SingleSelect } from '../../../../../components/SingleSelect/SingleSelect';
import { Table } from '@tanstack/react-table';
import { Company, SelectItem } from '../../../../../types';
import { useCompanies } from '../../../../../queries/useCompanies';
import { useAtom } from 'jotai';
import { selectedCompanyTypeFilterState } from '../../../../../state/UIState';

interface Props {
  table: Table<Company>;
}

export const TypeFilter = ({ table }: Props) => {
  const { data: companies } = useCompanies();
  const [selectedType, setSelectedType] = useAtom(selectedCompanyTypeFilterState);

  const sortedUniqueTypes = useMemo(() => {
    const unique = Array.from(table.getColumn('type')?.getFacetedUniqueValues().keys() ?? [])
      .sort()
      .map((value, id) => ({ id, value }));
    return unique;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, companies]);

  const onChangeTypeFilter = useCallback(
    (type: SelectItem | null) => {
      setSelectedType(type);
      table.getColumn('type')?.setFilterValue(type?.value ?? '');
    },
    [setSelectedType, table]
  );

  return (
    <SingleSelect
      style={{ width: '200px' }}
      options={sortedUniqueTypes}
      value={selectedType}
      onChange={(_, type) => onChangeTypeFilter(type)}
      disablePortal
      fieldPlaceholder='Filter by Type'
    />
  );
};
