import { DUE_DILIGENCE_SURVEY_NAME, SASB_ASSESSMENT_NAME } from '../constants/constants';
import { Company, WorkflowStep } from '../types';

export enum ASSESSMENT_REPORTS {
  SENSITIVE_BUSINESS_GUIDELINES = 'Sensitive Business Guidelines',
  COMPANY_DISCLOSURES = 'Company Disclosures',
  CONTROVERSIES_LIABILITIES = 'Controversies & Liabilities',
  INVESTOR_EXCLUSIONS_SCREENING = 'Investor Exclusions Screening',
  THEMATIC_ASSESSMENT = 'Thematic Assessment',
  SASB_ASSESSMENT = 'Investment Specific Assessment',
  INVESTOR_EXCLUSIONS_DUE_DILIGENCE = 'Investor Exclusions Due Diligence',
}

export const getCompanyAssessmentReports = (
  company: Company,
  allAssessmentSteps?: WorkflowStep[]
) => {
  const allAssessmentStepSurveyIds = allAssessmentSteps?.map((step) => {
    if (step?.name === DUE_DILIGENCE_SURVEY_NAME) {
      return company?.exclusionDueDiligenceSurvey?.id;
    }

    if (step?.name === SASB_ASSESSMENT_NAME) {
      return company?.industry?.survey?.id;
    }
    return step?.survey?.id;
  });

  return company?.reports?.filter((report) =>
    allAssessmentStepSurveyIds?.includes(report?.survey?.id)
  );
};
