import { TextField, styled, useTheme } from '@mui/material';
import { Modal } from '../Modal/Modal';
import { Typography } from '../Typography/Typography';
import { FormField } from '../FormField/FormField';
import React from 'react';
import { InfoContent } from './InfoContent';
import { CompanyPayload } from '../../queries/useCompanies';

const InfoWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
`;

const Img = styled('img')`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  object-fit: contain;
`;

const FormWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

interface Props {
  isOpen: boolean;
  title: string;
  confirmBtnText: string;
  onClose: () => void;
  handleSubmit: () => void;
  isConfirmDisabled: boolean;
  companyLogo?: string;
  companyName: string;
  inputLabel: string;
  handleError: (inputName: keyof CompanyPayload) => string | undefined;
  emailValue?: string;
  value?: string;
  handleChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined;
  handleBlur: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined;
  inputName: keyof CompanyPayload;
}

export const AssignEmailModal = ({
  isOpen,
  title,
  confirmBtnText,
  onClose,
  handleSubmit,
  isConfirmDisabled,
  companyLogo,
  companyName,
  inputLabel,
  handleError,
  emailValue,
  handleChange,
  handleBlur,
  inputName,
  value,
}: Props) => {
  const { colors } = useTheme();

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      confirmBtnText={confirmBtnText}
      onClose={onClose}
      onConfirm={handleSubmit}
      isConfirmDisabled={isConfirmDisabled}
      infoContent={emailValue ? <InfoContent email={emailValue} /> : null}
    >
      <InfoWrapper>
        {companyLogo && <Img src={companyLogo} />}
        <Typography variant='h4' color={colors.primary[90]}>
          {companyName}
        </Typography>
      </InfoWrapper>
      <FormWrapper>
        <FormField label={inputLabel} error={handleError(inputName)}>
          <TextField
            name={inputName}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            variant='outlined'
          />
        </FormField>
      </FormWrapper>
    </Modal>
  );
};
