import { styled } from '@mui/material';
import { Role, User, WorkflowStep } from '../../../../../types';
import { RolesSelect } from './RolesSelect';
import { ApprovalSettings } from './ApprovalSettings/ApprovalSettings';
import { BlockingSteps } from './BlockingSteps/BlockingSteps';
import { WorkflowStepPayload } from '../../../../../queries/useWorkflowSteps';

const Container = styled('div')`
  margin-top: 16px;
`;

const Divider = styled('div')`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary[20]};
  margin: 16px 0;
`;

interface Props {
  step: WorkflowStep;
  onUpdateStep: (id: number | string, stepPayload: Partial<WorkflowStepPayload>) => void;
}

export const CardContent = ({ step, onUpdateStep }: Props) => {
  const onUpdateAccessRoles = (roles: Role[]) => {
    onUpdateStep(step.id, { accessRoles: roles?.map((role) => role.id) });
  };

  const onToggleApprovalRequired = (
    e: React.ChangeEvent<HTMLInputElement>,
    isRequired: boolean
  ) => {
    onUpdateStep(step.id, { isApprovalRequired: isRequired });
  };

  const onUpdateApprovalRoles = (roles: Role[]) => {
    onUpdateStep(step.id, { approvalRoles: roles?.map((role) => role.id) });
  };
  const onUpdateNotifyUsers = (users: User[]) => {
    onUpdateStep(step.id, { notifyUsers: users?.map((user) => user.id) });
  };

  const onToggleIncludeBlockingSteps = (
    e: React.ChangeEvent<HTMLInputElement>,
    isIncluded: boolean
  ) => {
    onUpdateStep(step.id, { includeBlockingSteps: isIncluded });
  };

  const onUpdateBlockingSteps = (steps: WorkflowStep[]) => {
    onUpdateStep(step.id, { blockingSteps: steps?.map((step) => step.id) });
  };

  const onUpdateRequiredUsersApproval = (userIds: number[]) => {
    onUpdateStep(step.id, { requiredUsersApproval: userIds });
  };

  return (
    <Container>
      <RolesSelect
        initialRoles={step.accessRoles}
        onRolesSelect={onUpdateAccessRoles}
        label='Can Access'
      />
      <ApprovalSettings
        isApprovalRequired={step?.isApprovalRequired}
        onToggleApprovalRequired={onToggleApprovalRequired}
        approvalRoles={step?.approvalRoles || []}
        updateStepApprovalRoles={onUpdateApprovalRoles}
        initialSelectedUsers={step.notifyUsers}
        onUsersSelect={onUpdateNotifyUsers}
        requiredUsersApproval={step?.requiredUsersApproval || []}
        onUpdateRequiredUsersApproval={onUpdateRequiredUsersApproval}
        step={step}
      />
      <Divider />
      <BlockingSteps
        includeBlockingSteps={step.includeBlockingSteps}
        onToggleIncludeBlockingSteps={onToggleIncludeBlockingSteps}
        initialBlockingSteps={step.blockingSteps || []}
        onUpdateBlockingSteps={onUpdateBlockingSteps}
        step={step}
      />
    </Container>
  );
};
