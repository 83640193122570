import { ReviewOption } from '../../../../../../ReviewOption';
import { RISK_LEVEL, TReviewOptionConfig } from '../../../../../../../types';
import { useAtom } from 'jotai';
import { ESGRiskLevelSelector } from '../../../../../../../pages/SIngleCompany/components/ESGRiskLevelSelector';
import { ESGOpportunitiesSelector } from '../../../../../../../pages/SIngleCompany/components/ESGOpportunitiesSelector';
import { GovernanceScoreSelector } from '../../../../../../../pages/SIngleCompany/components/GovernanceScoreSelector';
import { styled } from '@mui/material';
import {
  esgGovernanceScoreOverrideState,
  esgOpportunitiesLevelOverrideState,
  esgRiskLevelOverrideState,
} from '../../../../../../../state/UIState';

export const FieldsContainer = styled('div')`
  padding: 12px 0 0 36px;
  border-top: ${({ theme }) => `1px solid ${theme.colors.primary[30]}`};
  margin-top: 12px;
`;

interface Props {
  reviewOption: TReviewOptionConfig;
  isEvaluated: boolean;
  isSelected: boolean;
  onToggleSelected: () => void;
}

export const OverrideOption = ({
  reviewOption,
  isEvaluated,
  isSelected,
  onToggleSelected,
}: Props) => {
  const [esgRiskLevel, setEsgRiskLevel] = useAtom(esgRiskLevelOverrideState);
  const [esgOpportunitiesLevel, setEsgOpportunitiesLevel] = useAtom(
    esgOpportunitiesLevelOverrideState
  );
  const [esgGovernanceScore, setEsgGovernanceScore] = useAtom(esgGovernanceScoreOverrideState);

  return (
    <ReviewOption
      isSelected={isSelected}
      isEvaluated={isEvaluated}
      onSelect={onToggleSelected}
      optionColor={reviewOption.color}
      label={reviewOption.name}
    >
      {isSelected && (
        <FieldsContainer onClick={(e) => e.stopPropagation()}>
          <ESGRiskLevelSelector
            esgRiskLevel={esgRiskLevel}
            onChangeEsgRiskLevel={(val) => setEsgRiskLevel(val?.id as RISK_LEVEL)}
          />
          <ESGOpportunitiesSelector
            esgOpportunitiesLevel={esgOpportunitiesLevel}
            onChangeEsgOpportunitiesLevel={(val) => setEsgOpportunitiesLevel(val?.id as RISK_LEVEL)}
          />
          <GovernanceScoreSelector
            governanceScore={esgGovernanceScore}
            onChangeGovernanceScore={(val) => setEsgGovernanceScore(Number(val?.id) as number)}
          />
        </FieldsContainer>
      )}
    </ReviewOption>
  );
};
