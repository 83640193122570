import { FC, useCallback, useMemo, useState } from 'react';
import {
  Company,
  CoreKpiRequestFrequency,
  SURVEY_FREQUENCY,
  SelectItem,
} from '../../../../types';
import { styled, useTheme } from '@mui/material';
import { useCreateCoreKpisRequest } from '../../../../queries/useRequests';
import { CompanyPayload, useUpdateCompany } from '../../../../queries/useCompanies';
import { useQueryClient } from 'react-query';
import { REPORTS } from '../../../../queries/useReports';
import { Modal } from '../../../Modal/Modal';
import { Typography } from '../../../Typography/Typography';
import { ChipsSelectorV2 } from '../../../ButtonSelector/ChipsSelectorV2';

interface Props {
  onClose: () => void;
  company: Company;
  isOpen: boolean;
  stepId: string | number;
}

const FormWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 8px;
  margin-top: 32px;
`;

export const CoreKpisCreateRequestModal: FC<Props> = ({ onClose, company, isOpen, stepId }) => {
  const { colors } = useTheme();
  const createCoreKpisRequest = useCreateCoreKpisRequest();
  const updateCompany = useUpdateCompany();
  const queryClient = useQueryClient();

  const options: SelectItem[] = useMemo(
    () => [
      {
        id: SURVEY_FREQUENCY.MONTHLY,
        value: 'Monthly',
      },
      {
        id: SURVEY_FREQUENCY.QUARTERLY,
        value: 'Quarterly',
      },
      {
        id: SURVEY_FREQUENCY.ANNUALLY,
        value: 'Annually',
      },
    ],
    []
  );
  const [selectedFrequency, setSelectedFrequency] = useState<SelectItem | null>(options[1]);

  const onSelectFrequency = useCallback(
    (item: SelectItem) => {
      setSelectedFrequency(item);
    },
    [setSelectedFrequency]
  );

  const onSubmit = useCallback(() => {
    createCoreKpisRequest.mutate(
      {
        companyId: company.id,
        frequency: selectedFrequency?.id as SURVEY_FREQUENCY,
        name: 'Core KPIs',
      },
      {
        onSuccess: () => {
          const companyPayload: Partial<CompanyPayload> = {
            id: company.id,
            isRecurringKpiAssigned: true,
            kpisDataFrequency: selectedFrequency?.id as CoreKpiRequestFrequency,
            completedWorkflowSteps: [...(company?.completedWorkflowSteps || []), stepId],
          };
          updateCompany.mutate(companyPayload, {
            onSuccess: () => {
              queryClient.refetchQueries(`${REPORTS}-${company.id}`);
              onClose();
            },
          });
        },
      }
    );
  }, [
    company?.completedWorkflowSteps,
    company.id,
    createCoreKpisRequest,
    onClose,
    queryClient,
    selectedFrequency?.id,
    stepId,
    updateCompany,
  ]);

  return (
    <Modal
      title='Create Core KPIs Request'
      onClose={onClose}
      infoText='The company will receive requests with selected frequency.'
      confirmBtnText='Create Request'
      onConfirm={onSubmit}
      isOpen={isOpen}
      contentContainerStyle={{ paddingBottom: '16px' }}
    >
      <FormWrapper>
        <Typography variant={'subtitle1'} color={colors.primary[90]} className='Label'>
          Frequency
        </Typography>
        <ChipsSelectorV2
          options={options}
          onSelect={onSelectFrequency}
          selectedItem={selectedFrequency}
        />
      </FormWrapper>
    </Modal>
  );
};
