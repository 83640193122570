import { useParams } from 'react-router';
import { useAllSteps } from '../../../hooks/useAllSteps';
import { useMemo } from 'react';
import { useReportsByCompanyId } from '../../../queries/useReports';
import { useCompanyById } from '../../../queries/useCompanies';
import { STEP_TYPE } from '../../../types';
import { DUE_DILIGENCE_SURVEY_NAME, SASB_ASSESSMENT_NAME } from '../../../constants/constants';

export const useCompanyReports = () => {
  const params = useParams();
  const { data: companyReports } = useReportsByCompanyId(Number(params.id));
  const { data: company } = useCompanyById(Number(params.id));
  const allSteps = useAllSteps(company?.type);

  const allAssessmentSteps = useMemo(
    () => allSteps?.filter((step) => step?.type === STEP_TYPE.ASSESSMENT),
    [allSteps]
  );

  const allAssessmentStepSurveyIds = useMemo(
    () =>
      allAssessmentSteps?.map((step) => {
        if (step?.name === DUE_DILIGENCE_SURVEY_NAME) {
          return company?.exclusionDueDiligenceSurvey?.id;
        }

        if (step?.name === SASB_ASSESSMENT_NAME) {
          return company?.industry?.survey?.id;
        }
        return step?.survey?.id;
      }),
    [allAssessmentSteps, company]
  );

  const assessmentReports = useMemo(
    () =>
      companyReports?.filter((report) => allAssessmentStepSurveyIds?.includes(report?.survey?.id)),
    [allAssessmentStepSurveyIds, companyReports]
  );

  const nonAssessmentReports = useMemo(
    () =>
      companyReports?.filter((report) => !allAssessmentStepSurveyIds?.includes(report?.survey?.id)),
    [allAssessmentStepSurveyIds, companyReports]
  );

  return { assessmentReports, nonAssessmentReports };
};
