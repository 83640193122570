import { AnalystRating } from './components/AnalystRating';
import { ExclusionSection } from './components/ExclusionSection';
import { useParams } from 'react-router';
import { useCompanyById, useUpdateCompany } from '../../queries/useCompanies';
import { useEffect } from 'react';
import { COMPANY_STAGE } from '../../types';
import { CompanySection } from './components/CompanySection';

export const NotControlledCompany = () => {
  const params = useParams();
  const { isLoading: isLoadingCompany, data: company } = useCompanyById(Number(params.id));
  const updateCompany = useUpdateCompany();

  useEffect(() => {
    if (company?.stage !== COMPANY_STAGE.ESG_COMPLETE) {
      updateCompany.mutate({ id: company?.id, stage: COMPANY_STAGE.ESG_COMPLETE });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!company || isLoadingCompany) return null;

  return (
    <CompanySection>
      <AnalystRating />
      <ExclusionSection />
    </CompanySection>
  );
};
