import { useMutation } from 'react-query';
import { useLoadingBar } from '../hooks/useLoadingBar';
import { useToastMessage } from '../hooks/useToastMessage';
import { Http } from '../services/Http';
import { LoadingId, Request, SURVEY_FREQUENCY } from '../types';

export interface RequestPayload {
  companyId: number;
  surveyId: number;
  frequency: SURVEY_FREQUENCY;
  name: string;
  sendEmail?: boolean;
}

export interface CoreKpisRequestPayload {
  companyId: number;
  frequency: SURVEY_FREQUENCY;
  name: string;
}

async function createRequest(payload: RequestPayload): Promise<Request> {
  const { data } = await Http.axios.post<RequestPayload, Request>(`/request`, payload);
  return data;
}

export function useCreateRequest() {
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  return useMutation(createRequest, {
    onMutate: async () => {
      startLoading(LoadingId.createRequest);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to create request' });
    },
    onSettled: () => {
      stopLoading(LoadingId.createRequest);
    },
  });
}

async function createCoreKpisRequest(payload: CoreKpisRequestPayload): Promise<Request> {
  const { data } = await Http.axios.post<CoreKpisRequestPayload, Request>(
    `/request/core-kpis`,
    payload
  );
  return data;
}

export function useCreateCoreKpisRequest() {
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  return useMutation(createCoreKpisRequest, {
    onMutate: async () => {
      startLoading(LoadingId.createRequest);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to create core KPIs request' });
    },
    onSettled: () => {
      stopLoading(LoadingId.createRequest);
    },
  });
}
