import { useAtom } from 'jotai';
import { WorkflowStep } from '../../../../../types';
import { StepCard } from '../../../components/StepCard/StepCard';
import { StepsList } from '../../../components/StepsList';
import { activePortCoStepState } from '../../../../../state/UIState';
import {
  WorkflowStepPayload,
  useDeleteStep,
  useUpdateStep,
  useUpdateSteps,
} from '../../../../../queries/useWorkflowSteps';
import { useCallback } from 'react';
import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { useAppConfig, useUpdateAppConfig } from '../../../../../queries/useAppConfig';

interface Props {
  steps: WorkflowStep[];
  stageId: number;
}

export const PortCoStepsList = ({ steps, stageId }: Props) => {
  const [activeStep, setActiveStep] = useAtom(activePortCoStepState);

  const { mutate: updateStep } = useUpdateStep();
  const { mutate: updateSteps } = useUpdateSteps(stageId);
  const { mutate: deleteStep } = useDeleteStep(stageId);
  const { data: appConfigData } = useAppConfig();
  const updateAppConfig = useUpdateAppConfig();

  const onUpdateStep = useCallback(
    (id: number | string, stepPayload: Partial<WorkflowStepPayload>) => {
      updateStep(
        { ...stepPayload, id, stage: stageId },
        {
          onSuccess: async () => {
            if (!appConfigData) return;

            await updateAppConfig.mutateAsync({
              data: { ...appConfigData?.data, isControlledWorkflowUpToDate: false },
            });
          },
        }
      );
    },
    [appConfigData, stageId, updateAppConfig, updateStep]
  );

  const handleReorder = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (!active?.id || !over?.id || !steps?.length) return;

      const oldIndex = steps?.findIndex((item) => item.id === active.id);
      const newIndex = steps?.findIndex((item) => item.id === over.id);

      const reorderedItems = arrayMove(steps, oldIndex, newIndex);

      const payload = {
        data: reorderedItems.map((item, i) => ({
          name: item.name,
          order: i + 1,
          id: item.id,
          stage: stageId,
        })),
      };

      updateSteps(payload, {
        onSuccess: async () => {
          if (!appConfigData) return;

          await updateAppConfig.mutateAsync({
            data: { ...appConfigData?.data, isControlledWorkflowUpToDate: false },
          });
        },
      });
    },
    [appConfigData, stageId, steps, updateAppConfig, updateSteps]
  );

  const onDeleteStep = useCallback(
    (id: number | string) => {
      deleteStep(id, {
        onSuccess: async () => {
          if (!appConfigData) return;

          await updateAppConfig.mutateAsync({
            data: { ...appConfigData?.data, isControlledWorkflowUpToDate: false },
          });
        },
      });
    },
    [appConfigData, deleteStep, updateAppConfig]
  );

  const onCardClick = useCallback(
    (step: WorkflowStep) => {
      if (step?.id === activeStep?.id) {
        setActiveStep(null);
        return;
      }

      setActiveStep(step);
    },
    [activeStep?.id, setActiveStep]
  );

  return (
    <StepsList steps={steps} handleReorder={handleReorder}>
      {steps?.map((step) => {
        return (
          <StepCard
            step={step}
            key={step?.id}
            activeStep={activeStep}
            onCardClick={() => onCardClick(step)}
            onUpdateStep={onUpdateStep}
            onDeleteStep={onDeleteStep}
          />
        );
      })}
    </StepsList>
  );
};
