import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { Modal } from '../../../../../components/Modal/Modal';
import { STEP_TYPE, SelectItem, WorkflowStep } from '../../../../../types';
import { Typography } from '../../../../../components/Typography/Typography';
import { useTheme } from '@mui/material';
import { ChipsSelectorV2 } from '../../../../../components/ButtonSelector/ChipsSelectorV2';
import { useStepsByType } from './useStepsByType';
import { StepsList } from './StepsList';
import { generateId } from '../../../../../utils/generateId';
import {
  DUE_DILIGENCE_SURVEY_NAME,
  INVESTOR_EXCLUSIONS_SURVEY_ID,
  STEP_TYPE_LABELS,
} from '../../../../../constants/constants';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  stageId: number;
  onAddStep: (step: Partial<WorkflowStep> | null) => Promise<WorkflowStep | undefined>;
}

type StepOption = {
  id: STEP_TYPE;
  value: string;
};

const typeOptions: StepOption[] = [
  {
    id: STEP_TYPE.ASSESSMENT,
    value: STEP_TYPE_LABELS[STEP_TYPE.ASSESSMENT],
  },
  {
    id: STEP_TYPE.REQUEST,
    value: STEP_TYPE_LABELS[STEP_TYPE.REQUEST],
  },
  {
    id: STEP_TYPE.COMPANY_INFO,
    value: STEP_TYPE_LABELS[STEP_TYPE.COMPANY_INFO],
  },
];

export const AddStepModal = ({ isOpen, setIsOpen, onAddStep }: Props) => {
  const { colors } = useTheme();

  const [selectedOption, setSelectedOption] = useState<SelectItem>(typeOptions[0]);
  const [selectedStep, setSelectedStep] = useState<Partial<WorkflowStep> | null>(null);

  const stepsBySelectedType = useStepsByType((selectedOption as StepOption).id);

  const steps: Partial<WorkflowStep>[] = useMemo(() => {
    return stepsBySelectedType?.map((s) => {
      return {
        ...s,
        id: generateId(),
      };
    });
  }, [stepsBySelectedType]);

  const onConfirm = async () => {
    if (selectedStep?.survey?.id === INVESTOR_EXCLUSIONS_SURVEY_ID) {
      const createdStep = await onAddStep(selectedStep);
      onAddStep({
        name: DUE_DILIGENCE_SURVEY_NAME,
        type: STEP_TYPE.ASSESSMENT,
        accessRoles: selectedStep.accessRoles,
        blockingSteps: createdStep ? [createdStep] : [],
        includeBlockingSteps: true,
      });
      setIsOpen(false);
      return;
    }

    onAddStep(selectedStep);
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      title='Add Step'
      onClose={() => setIsOpen(false)}
      onConfirm={onConfirm}
      cancelBtnText='Cancel'
      confirmBtnText='Add Step'
      isConfirmDisabled={false}
    >
      <div>
        <Typography variant={'srOnly'} color={colors.primary[90]} style={{ marginBottom: '4px' }}>
          Type
        </Typography>
        <ChipsSelectorV2
          options={typeOptions}
          onSelect={setSelectedOption}
          selectedItem={selectedOption}
        />

        <StepsList steps={steps} selectedStep={selectedStep} setSelectedStep={setSelectedStep} />
      </div>
    </Modal>
  );
};
