import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { userState } from '../state/UIState';
import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import { QUESTION_TYPE, QuestionOption, Response, SingleAssessmentQuestionRow } from '../types';
import { getFileName } from '../utils/getFileName';

const getAnswerValue = (type: QUESTION_TYPE, answer?: Response) => {
  if (type === QUESTION_TYPE.SINGLE) {
    return (answer as QuestionOption)?.name || '';
  }

  if (type === QUESTION_TYPE.MULTI) {
    return (answer as QuestionOption[])?.map((option) => option.name).join(', ') ?? '';
  }

  return (answer as string) || '';
};

enum WORKSHEET_KEYS {
  QUESTION = 'question',
  ANSWER = 'answer',
  OVERRIDE = 'override',
  OVERRIDDEN_BY = 'overriddenBy',
  EVALUATION_SCORE = 'evaluationScore',
}

type TCol = {
  header: string;
  key: WORKSHEET_KEYS;
  width?: number;
};

type TRow = Partial<Record<WORKSHEET_KEYS, string>>;

export const useExportSingleAssessment = () => {
  const user = useAtomValue(userState);

  const onExportAssessment = useCallback(
    async ({
      data,
      surveyName,
      showOverrideColumn,
      hasEvaluation,
    }: {
      data: SingleAssessmentQuestionRow[];
      surveyName: string;
      showOverrideColumn?: boolean;
      hasEvaluation?: boolean;
    }) => {
      const workbook = new ExcelJS.Workbook();
      workbook.creator = user?.fullName || '';
      workbook.created = new Date();
      workbook.properties.date1904 = true;

      const worksheet = workbook.addWorksheet(surveyName);

      const cols: TCol[] = [
        { header: 'Question', key: WORKSHEET_KEYS.QUESTION, width: 100 },
        { header: 'Answer', key: WORKSHEET_KEYS.ANSWER, width: 30 },
      ];

      if (showOverrideColumn) {
        cols.push({ header: 'Override', key: WORKSHEET_KEYS.OVERRIDE, width: 30 });
        cols.push({ header: 'Overridden By', key: WORKSHEET_KEYS.OVERRIDDEN_BY, width: 15 });
      }

      if (hasEvaluation) {
        cols.push({ header: 'Evaluation Score', key: WORKSHEET_KEYS.EVALUATION_SCORE, width: 15 });
      }

      worksheet.columns = cols;

      const filteredData = data.filter((row) => row.type !== QUESTION_TYPE.TABLE);

      filteredData.forEach((row) => {
        let worksheetRow: TRow = {
          question: row.question,
          answer: getAnswerValue(row.type, row.answer),
        };

        if (showOverrideColumn) {
          worksheetRow = {
            ...worksheetRow,
            override: getAnswerValue(row.type, row.overrideAnswer),
            overriddenBy: row.overriddenBy,
          };
        }

        if (hasEvaluation) {
          worksheetRow = {
            ...worksheetRow,
            evaluationScore:
              row.evaluationScore !== undefined && row.evaluationScore !== null
                ? String(row.evaluationScore)
                : '',
          };
        }

        worksheet.addRow(worksheetRow);
      });

      worksheet.getColumn(WORKSHEET_KEYS.QUESTION).alignment = {
        wrapText: true,
        vertical: 'top',
        horizontal: 'left',
      };
      worksheet.getColumn(WORKSHEET_KEYS.ANSWER).alignment = {
        wrapText: true,
        vertical: 'top',
        horizontal: 'left',
      };

      if (showOverrideColumn) {
        worksheet.getColumn(WORKSHEET_KEYS.OVERRIDE).alignment = {
          wrapText: true,
          vertical: 'top',
          horizontal: 'left',
        };
        worksheet.getColumn(WORKSHEET_KEYS.OVERRIDDEN_BY).alignment = {
          vertical: 'top',
          horizontal: 'left',
        };
      }

      if (hasEvaluation) {
        worksheet.getColumn(WORKSHEET_KEYS.EVALUATION_SCORE).alignment = {
          vertical: 'top',
          horizontal: 'left',
        };
      }

      const buffer = await workbook.xlsx.writeBuffer();
      FileSaver.saveAs(new Blob([buffer]), `${getFileName(surveyName)}.xlsx`);
    },
    [user?.fullName]
  );

  return onExportAssessment;
};
