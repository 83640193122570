import { styled, TextField, useTheme } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { Typography } from '../../../components/Typography/Typography';
import { EvaluationRulesConfig } from './EvaluationRulesConfig/EvaluationRulesConfig';
import { useAtom } from 'jotai';
import { activeSurveyState, includeEvaluationState } from '../../../state/UIState';
import { Modal } from '../../../components/Modal/Modal';
import { SURVEY_TYPE } from '../../../types';

interface Props {
  title: string;
  surveyName: string;
  onConfirm: (value: string, description: string, includeEvaluation?: boolean) => void;
  confirmText?: string;
  onClose?: () => void;
  isOpen: boolean;
}

const Wrapper = styled('div')``;

const EvaluationWrapper = styled('div')`
  margin-top: 16px;
`;

export const EditSurveyModal: FC<Props> = ({
  title,
  onConfirm,
  surveyName,
  confirmText = 'Confirm',
  onClose = () => undefined,
  isOpen,
}) => {
  const { colors } = useTheme();
  const [inputValue, setInputValue] = useState(surveyName);
  const [activeSurvey] = useAtom(activeSurveyState);
  const [description, setDescription] = useState(
    activeSurvey?.description ? activeSurvey.description : ''
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const [includeEvaluation, setIncludeEvaluation] = useAtom(includeEvaluationState);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      title={title}
      onConfirm={() => onConfirm(inputValue, description, includeEvaluation)}
      cancelBtnText='Cancel'
      confirmBtnText={confirmText}
    >
      <Wrapper>
        <Typography variant='caption' color={colors.primary[90]} style={{ paddingBottom: '10px' }}>
          Name
        </Typography>
        <TextField
          value={inputValue}
          onChange={onChange}
          placeholder='Survey name'
          style={{ width: '100%' }}
          inputRef={inputRef}
        />
        <Typography
          variant='caption'
          color={colors.primary[90]}
          style={{ paddingBottom: '10px', marginTop: '16px' }}
        >
          Description
        </Typography>
        <TextField
          value={description}
          onChange={onDescriptionChange}
          placeholder='Description'
          style={{ width: '100%' }}
        />
        {!activeSurvey || (activeSurvey?.type === SURVEY_TYPE.USER && !activeSurvey?.isAssigned) ? (
          <EvaluationWrapper>
            <EvaluationRulesConfig
              includeEvaluation={includeEvaluation}
              toggleIncludeEvaluation={() => setIncludeEvaluation((prev) => !prev)}
            />
          </EvaluationWrapper>
        ) : null}
      </Wrapper>
    </Modal>
  );
};
