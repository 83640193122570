import { FC, useMemo } from 'react';
import { EvaluationBox } from './EvaluationBox';
import { ReviewTabConfig, SurveyQuestion } from '../../../../../types';
import { QuestionInstructions } from '../QuestionInstructions';
import { ReviewReportTabs } from '../../../../../components/ReviewReportTabs/ReviewReportTabs';

interface Props {
  question: SurveyQuestion;
}

export const EvaluateTabs: FC<Props> = ({ question }) => {
  const tabsConfig: ReviewTabConfig[] = useMemo(() => {
    const tabs = [];
    const evaluationTabConfig = {
      tab: 'YOUR EVALUATION',
      tabComponent: <EvaluationBox question={question} />,
    };

    const instructionTabConfig = {
      tab: 'INSTRUCTIONS',
      tabComponent: <QuestionInstructions instructions={question.instructions} />,
    };

    if (question?.includeEvaluation) {
      tabs.push(evaluationTabConfig);
    }

    if (question?.includeInstructions) {
      tabs.push(instructionTabConfig);
    }

    return tabs;
  }, [question]);

  return <ReviewReportTabs tabsConfig={tabsConfig} />;
};
