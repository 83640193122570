import { useAtom, useAtomValue } from 'jotai';
import {
  activeReportState,
  reportReviewCompletedPercentageState,
  sectionsState,
  userState,
} from '../../../state/UIState';
import { REPORTS, useUpdateReport } from '../../../queries/useReports';
import { useQueryClient } from 'react-query';
import { useToastMessage } from '../../../hooks/useToastMessage';
import { useNavigate } from 'react-router';
import { useIsStepCompleted } from '../hooks/useIsStepCompleted';
import { useUpdateCompany } from '../../../queries/useCompanies';
import { useWorkflowStepForReport } from '../hooks/useWrokflowStepForReport';
import { Fragment, useCallback, useEffect, useMemo } from 'react';
import { REPORT_STATUS, Report, SURVEY_STAGE, SURVEY_TYPE, SurveySection } from '../../../types';
import { REQUEST_GROUPS } from '../../../queries/useRequestGroups';
import { ROUTES } from '../../../constants/routes';
import { flattenTree } from '../../../utils/treeUtilities';
import { ReviewQuestion } from './ReviewQuestion/ReviewQuestion';
import { useUpdateCompanyExclusions } from '../useUpdateCompanyExclusions';
import { DUE_DILIGENCE_SURVEY_NAME } from '../../../constants/constants';
import { useUpdateCompanyChecklistData } from '../hooks/useUpdateCompanyChecklistData';
import { useUpdateCompanyKpis } from '../hooks/useUpdateCompanyKpis';
import { Button, styled, useTheme } from '@mui/material';
import { ACTION_BAR_HEIGHT, HEADER_HEIGHT } from '../../../constants/layoutSizes';
import { ActionButtonsFooter } from '../../../components/ActionButtonsFooter/ActionButtonsFooter';
import { MainContentSection } from './MainContentSection';

const Wrapper = styled('div')`
  padding: 0 40px 24px 40px;
  flex-grow: 1;
  height: calc(100vh - ${HEADER_HEIGHT}px - ${ACTION_BAR_HEIGHT}px);
  overflow-y: auto;
  padding-bottom: 60px;
  & > div:last-child > div:last-child {
    border-bottom: none;
  }
  position: relative;
`;
const Container = styled('div')`
  position: relative;
  width: 100%;
`;

export const ReviewMainContent = () => {
  const [sections] = useAtom(sectionsState);
  const updateReport = useUpdateReport();
  const [report, setReport] = useAtom(activeReportState);
  const queryClient = useQueryClient();
  const { pushSuccessToast, pushWarningToast } = useToastMessage();
  const navigate = useNavigate();
  const [completedPercentage] = useAtom(reportReviewCompletedPercentageState);
  const { colors } = useTheme();

  const getIsStepCompleted = useIsStepCompleted();
  const { updateCompanyChecklistData } = useUpdateCompanyChecklistData();
  const { updateCompanyCoreKpis } = useUpdateCompanyKpis();

  const user = useAtomValue(userState);

  const updateCompany = useUpdateCompany();

  const workflowStep = useWorkflowStepForReport();

  const { updateCompanyExclusions } = useUpdateCompanyExclusions();

  const onSubmitEvaluation = useCallback(async () => {
    if (!report) return;

    const reportApprovedBy = user?.id ? [...(report?.approvedBy || []), user?.id] : [];

    let reportPayload: Partial<Report> = {
      id: report.id,
      approvedBy: reportApprovedBy,
      status: REPORT_STATUS.APPROVED,
    };

    const isCompletedStep = getIsStepCompleted(reportApprovedBy);

    if (workflowStep) {
      reportPayload = {
        ...reportPayload,
        status: isCompletedStep ? REPORT_STATUS.APPROVED : REPORT_STATUS.IN_REVIEW,
      };
    }

    await updateReport.mutateAsync(reportPayload, {
      onSuccess: async (report) => {
        setReport((report) => {
          if (!report) return report;
          return { ...report, status: REPORT_STATUS.APPROVED };
        });
        queryClient.refetchQueries(`${REQUEST_GROUPS}-${report.request.requestGroup?.id}`);
        queryClient.refetchQueries(`${REPORTS}-${report.company.id}`);
        queryClient.refetchQueries(`${REPORTS}-${report.id}`);

        if (workflowStep && isCompletedStep) {
          await updateCompany.mutateAsync({
            id: report.company.id,
            completedWorkflowSteps: [
              ...(report?.company?.completedWorkflowSteps || []),
              workflowStep?.id,
            ],
          });
        }

        if (
          report.survey.type === SURVEY_TYPE.SYSTEM &&
          report.survey.name === DUE_DILIGENCE_SURVEY_NAME
        ) {
          updateCompanyExclusions();
        }

        if (report.survey.stage === SURVEY_STAGE.CORE_KPI) {
          await updateCompanyCoreKpis();
        }

        if (
          report.survey.stage === SURVEY_STAGE.CHECKLIST_ANNUALLY ||
          report.survey.stage === SURVEY_STAGE.CHECKLIST_QUARTERLY
        ) {
          await updateCompanyChecklistData();
        }

        pushSuccessToast({
          message: `${report.company.name} response was successfully evaluated.`,
        });

        if (window.history.state.idx === 0) {
          navigate(`/${ROUTES.COMPANIES}/${report.company.id}/`);
        } else {
          navigate(-1);
        }
      },
    });
  }, [
    getIsStepCompleted,
    navigate,
    pushSuccessToast,
    queryClient,
    report,
    setReport,
    updateCompany,
    updateCompanyChecklistData,
    updateCompanyCoreKpis,
    updateCompanyExclusions,
    updateReport,
    user?.id,
    workflowStep,
  ]);

  const onReject = useCallback(async () => {
    if (!report) return;

    let reportPayload: Partial<Report> = { id: report.id, status: REPORT_STATUS.REJECTED };

    if (workflowStep) {
      reportPayload = {
        ...reportPayload,
        rejectedBy: user?.id ? [...(report?.rejectedBy || []), user?.id] : [],
      };
    }

    await updateReport.mutateAsync(reportPayload, {
      onSuccess: () => {
        queryClient.refetchQueries(`${REQUEST_GROUPS}-${report.request.requestGroup?.id}`);
        queryClient.refetchQueries(`${REPORTS}-${report.company.id}`);
        pushWarningToast({
          message: `${report.company.name} response was rejected.`,
        });
        if (window.history.state.idx === 0) {
          navigate(`/${ROUTES.COMPANIES}/${report.company.id}/`);
        } else {
          navigate(-1);
        }
      },
    });
  }, [navigate, pushWarningToast, queryClient, report, updateReport, user?.id, workflowStep]);

  useEffect(() => {
    if (!report) return;
    if (completedPercentage !== 100 && report?.status === REPORT_STATUS.APPROVED) {
      updateReport.mutate(
        { id: report.id, status: REPORT_STATUS.IN_REVIEW },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(`${REQUEST_GROUPS}-${report.request.requestGroup?.id}`);
            queryClient.refetchQueries(`${REQUEST_GROUPS}-${report.request.requestGroup?.id}`);
            queryClient.refetchQueries(`${REQUEST_GROUPS}-${report.request.requestGroup?.id}`);
            queryClient.refetchQueries(`${REPORTS}-${report.company.id}`);
            setReport((prev) => (prev ? { ...prev, status: REPORT_STATUS.IN_REVIEW } : prev));
          },
          onError: (e) => {
            throw e;
          },
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedPercentage]);

  const isSubmitDisabled = useMemo(() => {
    return completedPercentage !== 100 || report?.status === REPORT_STATUS.APPROVED;
  }, [completedPercentage, report?.status]);
  const isRejectDisabled = useMemo(() => {
    return report?.status === REPORT_STATUS.REJECTED;
  }, [report?.status]);

  const renderSubsections = (section: SurveySection) => {
    const [, ...flatSubsections] = flattenTree([section]);
    if (flatSubsections && flatSubsections.length) {
      return (
        <Fragment>
          {flatSubsections.map((subsection) => (
            <MainContentSection key={subsection.id} section={subsection} isSubsection>
              {subsection.questions.map((question, i) => (
                <ReviewQuestion key={question.id} question={question} order={i + 1} />
              ))}
            </MainContentSection>
          ))}
        </Fragment>
      );
    }

    return null;
  };

  return (
    <Container>
      <Wrapper>
        {sections.map((section) => (
          <MainContentSection key={section.id} section={section}>
            {section.questions.map((question, i) => (
              <ReviewQuestion
                key={question.id}
                question={question}
                isCoreKpi={report?.survey?.stage === SURVEY_STAGE.CORE_KPI}
                order={i + 1}
              />
            ))}
            {renderSubsections(section)}
          </MainContentSection>
        ))}
      </Wrapper>
      <ActionButtonsFooter>
        <>
          <Button
            variant='contained'
            onClick={onSubmitEvaluation}
            disabled={isSubmitDisabled}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            Submit Evaluation
          </Button>
          <Button
            variant='contained'
            style={{ width: '120px' }}
            sx={{
              '&.MuiButton-contained': { backgroundColor: colors.error[40] },
              '&.MuiButton-contained:hover': { backgroundColor: colors.error[40] },
            }}
            onClick={onReject}
            disabled={isRejectDisabled}
          >
            Reject
          </Button>
        </>
      </ActionButtonsFooter>
    </Container>
  );
};
