import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../../Typography/Typography';
import { EsgRiskIcon } from '../../../../../pages/SIngleCompany/components/EsgRiskIcon';
import { useParams } from 'react-router';
import { useCompanyById } from '../../../../../queries/useCompanies';
import { EsgOpportunitiesIcon } from '../../../../../pages/SIngleCompany/components/EsgOpportunitiesIcon';
import { GovernanceScoreIcon } from '../../../../../pages/SIngleCompany/components/GovernanceScoreIcon';

const Container = styled('div')`
  width: 40%;
  border-radius: 4px;
  border: ${({ theme }) => `1px solid ${theme.colors.gray[30]}`};
  background-color: ${({ theme }) => theme.colors.gray[20]};
  padding: 12px;
`;

const RatingWrapper = styled('div')`
  margin-bottom: 16px;
`;

const RatingContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 8px;

  svg {
    width: 16px;
  }
`;

export const AnalystRatingPreview = () => {
  const { colors } = useTheme();
  const params = useParams();

  const { data: company } = useCompanyById(Number(params.id));

  return (
    <Container>
      <RatingWrapper>
        <Typography variant='srOnly' color={colors.primary[90]}>
          ESG Risk Level
        </Typography>
        <RatingContainer>
          <EsgRiskIcon esgRiskLevel={company?.esgRiskLevel} />
          <Typography variant='subtitle2' color={colors.primary[90]}>
            {company?.esgRiskLevel}
          </Typography>
        </RatingContainer>
      </RatingWrapper>
      <RatingWrapper>
        <Typography variant='srOnly' color={colors.primary[90]}>
          ESG Opportunities
        </Typography>
        <RatingContainer>
          <EsgOpportunitiesIcon esgOpportunitiesLevel={company?.esgOpportunitiesLevel} />
          <Typography variant='subtitle2' color={colors.primary[90]}>
            {company?.esgOpportunitiesLevel}
          </Typography>
        </RatingContainer>
      </RatingWrapper>
      {company?.governanceScore && (
        <RatingWrapper>
          <Typography variant='srOnly' color={colors.primary[90]}>
            EU Governance Score
          </Typography>
          <RatingContainer>
            <GovernanceScoreIcon governanceScore={company?.governanceScore} />
            <Typography variant='subtitle2' color={colors.primary[90]}>
              {company?.governanceScore}
            </Typography>
          </RatingContainer>
        </RatingWrapper>
      )}
      {company?.analystRatingNotes && (
        <RatingWrapper>
          <Typography variant='srOnly' color={colors.primary[90]}>
            Notes
          </Typography>
          <Typography variant='subtitle1' color={colors.primary[90]}>
            {company?.analystRatingNotes}
          </Typography>
        </RatingWrapper>
      )}
    </Container>
  );
};
