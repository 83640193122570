import { useMemo } from 'react';
import { useParams } from 'react-router';
import { useCompanyById } from '../../../../../queries/useCompanies';
import { COMPANY_INFO_STEP } from '../../../../../types';
import { useAllSteps } from '../../../../../hooks/useAllSteps';

export const useAnalystRatingStep = () => {
  const params = useParams();

  const { data: company } = useCompanyById(Number(params.id));

  const allSteps = useAllSteps(company?.type);

  const step = useMemo(
    () => allSteps?.find((step) => step.companyInfoStep === COMPANY_INFO_STEP.ANALYST_RATING),
    [allSteps]
  );

  return step;
};
