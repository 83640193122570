import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { useAtom } from 'jotai';
import { FC, useCallback } from 'react';
import { activeQuestionIdState, questionState } from '../../../../../state/UIState';
import { SurveyQuestion } from '../../../../../types';
import { generateId } from '../../../../../utils/generateId';
import { QuestionOptionItem } from './QuestionOptionItem/QuestionOptionItem';
import { OptionsList } from './components/OptionsList';

interface Props {
  question: SurveyQuestion;
  questionNum: number;
}

export const QuestionOptionList: FC<Props> = ({ question, questionNum }) => {
  const [activeQuestionId] = useAtom(activeQuestionIdState);
  const [, setQuestion] = useAtom(questionState(activeQuestionId));

  const onAddOption = useCallback(() => {
    const newOption = {
      id: generateId(),
      name: `Option ${question.options.length + 1}`,
      justCreated: true,
    };
    const updatedQuestion = {
      ...question,
      options: [...question.options, newOption],
    };
    setQuestion(updatedQuestion);
  }, [question, setQuestion]);

  const handleReorder = (e: DragEndEvent) => {
    const { active, over } = e;
    if (over?.id && active.id !== over.id) {
      if (question.id !== activeQuestionId) {
        return;
      }
      const oldIndex = question.options.findIndex((s) => s.id === active.id);
      const newIndex = question.options.findIndex((s) => s.id === over.id);
      const reorderedOptions = arrayMove(question.options, oldIndex, newIndex);
      const updatedQuestion = {
        ...question,
        options: reorderedOptions,
      };
      setQuestion(updatedQuestion);
    }
  };

  return (
    <OptionsList
      handleReorder={handleReorder}
      question={question}
      onAddOption={onAddOption}
      activeQuestionId={activeQuestionId}
    >
      {question?.options.map((option) => (
        <QuestionOptionItem
          key={option.id}
          option={option}
          type={question.type}
          showDragIcon={question.options.length > 1 && activeQuestionId === question.id}
          questionNum={questionNum}
          question={question}
        />
      ))}
    </OptionsList>
  );
};
