import { useCallback } from 'react';
import { Multiselect } from '../../../../../components/Multiselect/Multiselect';
import { Table } from '@tanstack/react-table';
import { Company, SelectItem } from '../../../../../types';
import { useAtom } from 'jotai';
import { selectedCompanySectorsFilterState } from '../../../../../state/UIState';

interface Props {
  table: Table<Company>;
  sortedUniqueSectors: SelectItem[];
}

export const SectorsFilter = ({ table, sortedUniqueSectors }: Props) => {
  const [selectedSectors, setSelectedSectors] = useAtom(selectedCompanySectorsFilterState);

  const onChangeSectorsFilter = useCallback(
    (sectors: SelectItem[]) => {
      setSelectedSectors(sectors);
      table.getColumn('sectors')?.setFilterValue(sectors.map((s) => s.value));
    },
    [setSelectedSectors, table]
  );

  return (
    <Multiselect
      style={{ width: '220px' }}
      options={sortedUniqueSectors}
      value={selectedSectors}
      onChange={(_, val) => {
        onChangeSectorsFilter(val as SelectItem[]);
      }}
      disablePortal
      optionName='Sector'
      fieldPlaceholder='Filter by Sectors'
    />
  );
};
