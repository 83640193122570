import { createColumnHelper, DisplayColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import { CompanyCell } from '../Table/CellRenderers/CompanyCell';
import { useTheme } from '@mui/material';
import { Typography } from '../Typography/Typography';
import { PortfolioCompanyData } from './AssessmentSummaryTable';
import { RiskLevelCell } from './RiskLevelCell';
import { useAllSteps } from '../../hooks/useAllSteps';
import { COMPANY_TYPE, STEP_TYPE } from '../../types';
import { FlagsIndicator } from '../FlagsIndicator/FlagsIndicator';

const columnHelper = createColumnHelper<PortfolioCompanyData>();

export const useAssessmentSummaryColumns = (companiesType: COMPANY_TYPE) => {
  const { colors } = useTheme();

  const allSteps = useAllSteps(companiesType);

  const allAssessmentSteps = useMemo(
    () => allSteps?.filter((step) => step?.type === STEP_TYPE.ASSESSMENT),
    [allSteps]
  );

  return useMemo(() => {
    const staticColumns = [
      columnHelper.accessor('name', {
        cell: (info) => (
          <CompanyCell
            name={info.getValue()}
            logo={info.row.original.logoUrl}
            labelColor={colors.accent[50]}
          />
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Company
          </Typography>
        ),
        meta: {
          width: '240px',
          minWidth: '240px',
          maxWidth: '240px',
        },
        enableColumnFilter: true,
      }),
      columnHelper.accessor('esgRiskLevel', {
        cell: (info) => <RiskLevelCell riskLevel={info.getValue()} />,
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            ESG Risk Level
          </Typography>
        ),
        meta: {
          width: '128px',
          minWidth: '128px',
          maxWidth: '128px',
        },
        enableColumnFilter: true,
        filterFn: (row: any, columnId, selectedFilters) => {
          const value: string = row.getValue(columnId) || '';
          return selectedFilters.includes(value);
        },
      }),
      columnHelper.accessor('esgOpportunitiesLevel', {
        cell: (info) => (
          <Typography variant='subtitle1' color={colors.primary[90]}>
            {info.getValue()}
          </Typography>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            ESG Opportunities
          </Typography>
        ),
        meta: {
          width: '156px',
          minWidth: '156px',
          maxWidth: '156px',
        },
        enableColumnFilter: true,
        filterFn: (row: any, columnId, selectedFilters) => {
          const value: string = row.getValue(columnId) || '';
          return selectedFilters.includes(value);
        },
      }),
      columnHelper.accessor('governanceScore', {
        cell: (info) => (
          <Typography variant='subtitle1' color={colors.primary[90]}>
            {info.getValue()}
          </Typography>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Governance Score
          </Typography>
        ),
        meta: {
          width: '156px',
          minWidth: '156px',
          maxWidth: '156px',
        },
        enableColumnFilter: false,
      }),
    ];

    const dynamicColumns: DisplayColumnDef<PortfolioCompanyData>[] =
      allAssessmentSteps
        ?.map?.((step) => {
          return columnHelper.display({
            id: String(step?.survey?.id),
            cell: (info) => (
              <FlagsIndicator
                flagsNum={
                  info.row.original.assessmentReportFlags?.find(
                    (reportFlag) => reportFlag.surveyId === step?.survey?.id
                  )?.flagsNum || 0
                }
              />
            ),
            header: () => (
              <Typography variant='overline' color={colors.primary[70]}>
                {step?.name}
              </Typography>
            ),
            meta: {
              width: '215px',
              minWidth: '215px',
              maxWidth: '215px',
            },
            enableSorting: false,
          });
        })
        ?.filter((col) => Boolean(col)) || [];

    return [...staticColumns, ...dynamicColumns];
  }, [allAssessmentSteps, colors.accent, colors.primary]);
};
