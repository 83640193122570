import {
  ChecklistData,
  Company,
  COMPANY_STAGE,
  COMPANY_TYPE,
  CompanyKpisData,
  EsgScoreData,
  Industry,
  Region,
  RISK_LEVEL,
  SingleCompanyData,
  Survey,
} from '../types';

//need to be part of database, since we have these constants both in frontend and backend
export const esgWeights = {
  esgScore: 0.5,
  socialScore: 0.3,
  governanceScore: 0.33,
  environmentalScore: 0.37,
  controversies: 0.2,
  msciRatings: 0.2,
  trucost: 0.05,
  sustainalytics: 0.05,
};

export const companyData: SingleCompanyData = {
  esgTrendingPercent: 1.05,
  socialTrendingPercent: 0.18,
  environmentalTrendingPercent: 0.03,
  governanceTrendingPercent: -0.04,
  performanceTable: [
    {
      eKpiName: 'CO2 Emmisions (tonnes)',
      eKpiValue: 13.77,
      eKpiTrend: 'positive',
      sKpiName: 'Employees (FTEs)',
      sKpiValue: 13.96,
      sKpiTrend: 'positive',
      gKpiName: 'Code of Conduct',
      gKpiValue: 'Yes',
      gKpiTrend: 'positive',
    },
    {
      eKpiName: 'Electricity (Kwh)',
      eKpiValue: 55.4,
      eKpiTrend: 'negative',
      sKpiName: 'Women (FTEs)',
      sKpiValue: 57.61,
      sKpiTrend: 'positive',
      gKpiName: 'Anti-Corruption Policy',
      gKpiValue: 'Yes',
      gKpiTrend: 'positive',
    },
    {
      eKpiName: 'Business Travel by Air (miles)',
      eKpiValue: 93.94,
      eKpiTrend: 'negative',
      sKpiName: 'Men (FTEs)',
      sKpiValue: 74.03,
      sKpiTrend: 'negative',
      gKpiName: 'Cyber Security Function',
      gKpiValue: 'No',
      gKpiTrend: 'negative',
    },
    {
      eKpiName: 'Water Usage (m3)',
      eKpiValue: 49.47,
      eKpiTrend: 'positive',
      sKpiName: 'Diversity Policy',
      sKpiValue: 52.83,
      sKpiTrend: 'positive',
      gKpiName: null,
      gKpiValue: null,
      gKpiTrend: null,
    },
    {
      eKpiName: 'Waste Treated (tonnes)',
      eKpiValue: 84.83,
      eKpiTrend: 'positive',
      sKpiName: 'Sick Days (FTEs)',
      sKpiValue: 66.16,
      sKpiTrend: 'negative',
      gKpiName: null,
      gKpiValue: null,
      gKpiTrend: null,
    },
    {
      eKpiName: 'Environmental Incidents',
      eKpiValue: 'No',
      eKpiTrend: 'positive',
      sKpiName: 'Voluntary Turnover',
      sKpiValue: 79.36,
      sKpiTrend: 'positive',
      gKpiName: null,
      gKpiValue: null,
      gKpiTrend: null,
    },
    {
      eKpiName: null,
      eKpiValue: null,
      eKpiTrend: null,
      sKpiName: 'Workers Council',
      sKpiValue: 43.89,
      sKpiTrend: 'positive',
      gKpiName: null,
      gKpiValue: null,
      gKpiTrend: null,
    },
  ],
  exclusions: [
    {
      name: 'Tobacco',
    },
    {
      name: 'Corruption',
    },
    {
      name: 'Unethical marketing',
    },
  ],
  esgDrivers: [
    {
      name: 'Sustainability Drivers',
      drivers: [
        'Climate change and natural catastrophes (sustainability-related products)',
        'Demographic and lifestyle changes (ageing population, baby boomers)',
        'Regulatory change (Johnson report, Cooper review, Henry tax review, FOFA)',
        'Technology change and increased complexity (product complexity)',
      ],
    },
    {
      name: 'Intangible Drivers',
      drivers: [
        'Corporate governance structure',
        'Risk management and disclosure',
        'Quality of workplace (ability to attract and retain human capital)',
        'Quality of human capital and processes (ability to perform)',
        'Commitment to sustainability and the environment',
        'Commitment to the community',
      ],
    },
  ],
  esgSwotAnalysis: {
    strengths:
      'OpenAI has a majority independent board with fully independent committees and its board comprises a broad mix of relevant skills / experience.',
    opportunities:
      'OpenAI is well placed to benefit from sustainability drivers as increased longevity means a demand for both income and yield. Investment products for retirees is a growth opportunity.',
    weaknesses:
      'Use of options for directors each NED bought 200,000 options for 24c with strike price of $2.65 at listing (115% premium to issue price) and life of 5 years. At $3.30, these options deliver a profit of $82,000 at 12 Jul, 2015.',
    threats:
      'Potential for higher cost of funding as APRA changes securitisation rules in 2016. OpenAI securitises its loans and leases.',
  },
};

export const companyDefaultData: Company = {
  id: 404,
  created_at: '',
  name: 'Test Company',
  logoUrl: '',
  externalId: null,
  analyst: '',
  stage: COMPANY_STAGE.SCREENING,
  isRecurringKpiAssigned: false,
  isYearlyAssessmentAssigned: false,
  isQuarterlyChecklistAssigned: false,
  esgScoreData: {} as EsgScoreData,
  kpisData: {} as CompanyKpisData,
  checklistData: {} as ChecklistData,
  kpisDataFrequency: null,
  type: COMPANY_TYPE.CONTROLLED,
  esgRiskLevel: RISK_LEVEL.HIGH,
  esgOpportunitiesLevel: RISK_LEVEL.MEDIUM,
  analystRatingNotes: '',
  description: '',
  city: '',
  country: '',
  violatedExclusionsIds: [],
  areExclusionsExternallySaved: false,
  completedWorkflowSteps: [],
  analystRatingApprovedBy: [1],
  analystRatingRejectedBy: [],
  governanceScore: null,
  analystRatingReview: null,
  sectors: [],
  industry: {} as Industry,
  region: {} as Region,
  exclusionScreeningSurvey: {} as Survey,
  exclusionDueDiligenceSurvey: {} as Survey,
  objectives: [],
  reports: [],
  exclusions: [],
  externalEsgContact: null,
};
