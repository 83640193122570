import {
  SortingState,
  Table as RTable,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  Row,
  ColumnFiltersState,
} from '@tanstack/react-table';
import { Company, COMPANY_TYPE, Report, SelectItem, STEP_TYPE, USER_ROLE } from '../../types';
import { useAssessmentSummaryColumns } from './useAssessmentSummaryColumns';
import { Table } from '../Table/Table';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { styled, useTheme } from '@mui/material';
import { SearchInput } from '../SearchInput/SearchInput';
import { Multiselect } from '../Multiselect/Multiselect';
import { Typography } from '../Typography/Typography';
import { getNumberOfFlaggedAnswers } from '../../utils/getNumberOfFlaggedAnswers';
import { getCompanyAssessmentReports } from '../../utils/getCompanyAssessmentReports';
import { useLocation, useNavigate } from 'react-router';
import { ROUTES } from '../../constants/routes';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  activeRoleState,
  filteredCompaniesIdsState,
  selectedOpportunitiesFilterState,
  selectedRisksFilterState,
} from '../../state/UIState';
import { ResetFiltersButton } from '../ResetFiltersButton/ResetFiltersButton';
import { useAllSteps } from '../../hooks/useAllSteps';

const Container = styled('div')``;

const FiltersWrapper = styled('div')`
  display: flex;
  gap: 12px;
  margin-bottom: 8px;
`;
interface Props {
  companies: Company[];
  sorting: SortingState;
  setSorting: Dispatch<SetStateAction<SortingState>>;
  columnFilters: ColumnFiltersState;
  setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
  prevRouteName: string;
  companiesType: COMPANY_TYPE;
}

export type TAssessmentReportFlagData = {
  surveyId: number;
  name: string;
  flagsNum: number;
};

export type PortfolioCompanyData = Company & {
  assessmentReportFlags: TAssessmentReportFlagData[];
};

const getUniqueValuesFromTableColumn = (
  table: RTable<PortfolioCompanyData>,
  columnName: string
) => {
  const allValues = Array.from(table.getColumn(columnName)?.getFacetedUniqueValues().keys() ?? []);

  const unique =
    allValues
      .filter((value) => Boolean(value))
      .sort()
      .map((value, id) => ({ id, value })) ?? [];

  return unique;
};

const getAssessmentFlags = (assessmentReport?: Report) => {
  if (!assessmentReport) return 0;
  return getNumberOfFlaggedAnswers(assessmentReport) || 0;
};

export const AssessmentSummaryTable = ({
  companies,
  sorting,
  setSorting,
  columnFilters,
  setColumnFilters,
  prevRouteName,
  companiesType,
}: Props) => {
  const { colors } = useTheme();
  const navigate = useNavigate();

  const [selectedRisks, setSelectedRisks] = useAtom(selectedRisksFilterState);
  const [selectedOpportunities, setSelectedOpportunities] = useAtom(
    selectedOpportunitiesFilterState
  );

  const setFilteredCompaniesIds = useSetAtom(filteredCompaniesIdsState);
  const activeRole = useAtomValue(activeRoleState);

  const allSteps = useAllSteps(companiesType);

  const allAssessmentSteps = useMemo(
    () => allSteps?.filter((step) => step?.type === STEP_TYPE.ASSESSMENT),
    [allSteps]
  );

  const location = useLocation();

  const tableData: PortfolioCompanyData[] = useMemo(() => {
    return companies.map((company) => {
      const assessmentReports = getCompanyAssessmentReports(company, allAssessmentSteps);
      return {
        ...company,
        assessmentReportFlags: assessmentReports?.map((report) => ({
          surveyId: report.survey.id,
          name: report.survey.name,
          flagsNum: getAssessmentFlags(report),
        })),
      };
    });
  }, [allAssessmentSteps, companies]);

  const columns = useAssessmentSummaryColumns(companiesType);

  const table = useReactTable({
    data: tableData || [],
    columns,
    state: {
      sorting,
      columnFilters,
    },
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: (company) => String(company.id),
  });

  const tableRows = table.getRowModel().rows;

  useEffect(() => {
    setFilteredCompaniesIds(tableRows.map((row) => Number(row.id)));
  }, [setFilteredCompaniesIds, tableRows]);

  const sortedUniqueRisks = useMemo(() => {
    return getUniqueValuesFromTableColumn(table, 'esgRiskLevel');
  }, [table]);

  const sortedUniqueOpportunities = useMemo(() => {
    return getUniqueValuesFromTableColumn(table, 'esgOpportunitiesLevel');
  }, [table]);

  useEffect(() => {
    if (columnFilters?.length) return;
    setSelectedOpportunities(sortedUniqueOpportunities);
    setSelectedRisks(sortedUniqueRisks);
  }, [
    columnFilters?.length,
    setSelectedOpportunities,
    setSelectedRisks,
    sortedUniqueOpportunities,
    sortedUniqueRisks,
  ]);

  const onChangeSelectedRisks = useCallback(
    (risks: SelectItem[]) => {
      setSelectedRisks(risks);
      table.getColumn('esgRiskLevel')?.setFilterValue(risks.map((r) => r.value));
    },
    [setSelectedRisks, table]
  );
  const onChangeSelectedOpportunities = useCallback(
    (opportunities: SelectItem[]) => {
      setSelectedOpportunities(opportunities);
      table.getColumn('esgOpportunitiesLevel')?.setFilterValue(opportunities.map((o) => o.value));
    },
    [setSelectedOpportunities, table]
  );

  const onRowClick = useCallback(
    (row: Row<PortfolioCompanyData>) => {
      const companyId = row.id;
      navigate(`/${ROUTES.COMPANIES}/${companyId}/${ROUTES.COMPANY_OVERVIEW}`, {
        state: { prevRoute: location.pathname, prevRouteName: prevRouteName || '' },
      });
    },
    [location.pathname, navigate, prevRouteName]
  );

  const onResetFilters = useCallback(() => {
    setSelectedRisks(sortedUniqueRisks);
    setSelectedOpportunities(sortedUniqueOpportunities);
    setColumnFilters([]);
  }, [
    setColumnFilters,
    setSelectedOpportunities,
    setSelectedRisks,
    sortedUniqueOpportunities,
    sortedUniqueRisks,
  ]);

  const isResetDisabled = useMemo(() => {
    if (!columnFilters.length) return true;
  }, [columnFilters.length]);

  const title = useMemo(() => {
    if (activeRole === USER_ROLE.ANALYST) {
      return 'Summaries by company';
    }

    return 'Assessment Summary';
  }, [activeRole]);

  return (
    <Container>
      <Typography variant='h4' color={colors.primary[90]} style={{ marginBottom: '20px' }}>
        {title}
      </Typography>
      <FiltersWrapper>
        <SearchInput
          placeholder='Search for a company'
          style={{ width: '345px', marginRight: '12px' }}
          onClear={() => table.getColumn('name')?.setFilterValue('')}
          onChange={(e) => table.getColumn('name')?.setFilterValue(e.target.value)}
          value={table.getColumn('name')?.getFilterValue() ?? ''}
        />
        <Multiselect
          style={{ width: '240px' }}
          options={sortedUniqueRisks}
          value={selectedRisks}
          onChange={(_, val) => onChangeSelectedRisks(val as SelectItem[])}
          disablePortal
          optionName='ESG Risk Level'
          selectedTextMaxWidth='9rem'
          fieldPlaceholder='Filter by ESG Risk Levels'
        />
        <Multiselect
          style={{ width: '255px' }}
          options={sortedUniqueOpportunities}
          value={selectedOpportunities}
          onChange={(_, val) => onChangeSelectedOpportunities(val as SelectItem[])}
          disablePortal
          optionsName='ESG Opportunities'
          fieldPlaceholder='Filter by ESG Opportunities'
          selectedTextMaxWidth='9rem'
        />
        <ResetFiltersButton onResetFilters={onResetFilters} isResetDisabled={isResetDisabled} />
      </FiltersWrapper>
      <Table
        table={table}
        tdStyle={{ height: 'inherit' }}
        onRowClick={onRowClick}
        height={'calc(100vh - 122px)'}
      />
    </Container>
  );
};
