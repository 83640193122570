import {
  QUESTION_TYPE,
  QuestionOption as TQuestionOption,
} from '../../../../../../../../../../types';
import { useCallback, useState } from 'react';
import { SurveyQuestion } from '../../../../../../../../../../types';
import { Option } from '../../../../../components/Option';
import { useUpdateSubQuestion } from '../hooks/useUpdateSubquestion';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

interface Props {
  option: TQuestionOption;
  type: QUESTION_TYPE;
  showDragIcon: boolean;
  subQuestion: SurveyQuestion;
  questionOption: TQuestionOption;
}

export const SubQuestionOption = ({
  option,
  type,
  showDragIcon,
  subQuestion,
  questionOption,
}: Props) => {
  const [optionTitle, setOptionTitle] = useState(option?.name ?? '');

  const { setNodeRef, transform, transition, attributes, listeners } = useSortable(option);

  const { updateSubQuestion } = useUpdateSubQuestion();

  const onOptionRename = useCallback(
    (value: string) => {
      if ((value === option?.name && !option.justCreated) || !value || !subQuestion) {
        setOptionTitle(option?.name ?? '');
        return;
      }
      const updatedQuestion = {
        ...subQuestion,
        options: subQuestion.options.map((o) => {
          if (o.id === option?.id) {
            return {
              id: o.id,
              name: value,
            };
          }
          return o;
        }),
      };
      updateSubQuestion(updatedQuestion, questionOption);
    },
    [option?.id, option.justCreated, option?.name, questionOption, subQuestion, updateSubQuestion]
  );

  const onDeleteOption = useCallback(() => {
    if (!subQuestion) return;
    const updatedQuestion = {
      ...subQuestion,
      options: subQuestion.options.filter((o) => o.id !== option?.id),
    };
    updateSubQuestion(updatedQuestion, questionOption);
  }, [option?.id, questionOption, subQuestion, updateSubQuestion]);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Option
        onOptionRename={onOptionRename}
        showDragIcon={showDragIcon}
        option={option}
        questionType={type}
        onDeleteOption={onDeleteOption}
        optionTitle={optionTitle}
        setOptionTitle={setOptionTitle}
      />
    </div>
  );
};
