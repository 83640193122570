import { FC, useMemo } from 'react';
import { useFormik } from 'formik';
import { AnalystSchema as validationSchema } from './AnalystSchema';
import { Company } from '../../../../types';
import { CompanyPayload, useUpdateCompany } from '../../../../queries/useCompanies';
import { AssignEmailModal } from '../../../../components/AssignEmailModal/AssignEmailModal';

interface Props {
  onClose: () => void;
  company: Company;
  isOpen: boolean;
}

export const AnalystModal: FC<Props> = ({ onClose, company, isOpen }) => {
  const updateCompany = useUpdateCompany();

  const isReassign = useMemo(() => Boolean(company?.analyst), [company?.analyst]);

  const {
    handleChange,
    values,
    handleBlur,
    touched,
    errors,
    isValid,
    handleSubmit,
    dirty,
    resetForm,
  } = useFormik<Partial<CompanyPayload>>({
    initialValues: {
      analyst: '',
    },
    validationSchema,
    onSubmit: (values) => {
      onClose();
      updateCompany.mutate({ id: company.id, analyst: values.analyst });
      resetForm();
    },
  });

  const handleError = (field: keyof CompanyPayload) => (touched[field] ? errors[field] : '');

  return (
    <AssignEmailModal
      isOpen={isOpen}
      title={
        isReassign ? 'Reassign ESG Analyst to the Company' : 'Assign ESG Analyst to the Company'
      }
      confirmBtnText={isReassign ? 'Reassign' : 'Assign'}
      onClose={onClose}
      isConfirmDisabled={!isValid || !dirty}
      companyName={company?.name}
      companyLogo={company?.logoUrl}
      inputName='analyst'
      inputLabel={isReassign ? 'New ESG Analyst Email' : 'ESG Analyst Email'}
      handleChange={handleChange}
      handleBlur={handleBlur}
      handleError={handleError}
      handleSubmit={handleSubmit}
      emailValue={company?.analyst}
      value={values?.analyst || ''}
    />
  );
};
