import { useMemo } from 'react';
import { useCompanies } from '../../queries/useCompanies';
import { COMPANY_STAGE, COMPANY_TYPE, USER_ROLE } from '../../types';
import { PortfolioLayout } from '../../components/PortfolioLayout/PortfolioLayout';
import { PortfolioPageSkeletonLoader } from '../../components/SkeletonLoader/PortfolioPage.SkeletonLoader';
import { ComposureCharts } from '../../components/ComposureCharts/ComposureCharts';
import { AssessmentSummaryTable } from '../../components/AssessmentSummaryTable/AssessmentSummaryTable';
import { useAtom, useAtomValue } from 'jotai';
import {
  activeRoleState,
  issuersAssessmentSummaryFiltersState,
  issuersAssessmentSummarySortingState,
} from '../../state/UIState';

export const Issuers = () => {
  const { data: companies, isLoading } = useCompanies();

  const activeRole = useAtomValue(activeRoleState);

  const [sorting, setSorting] = useAtom(issuersAssessmentSummarySortingState);
  const [columnFilters, setColumnFilters] = useAtom(issuersAssessmentSummaryFiltersState);

  const esgCompleteNotControlledCompanies = useMemo(() => {
    return (
      companies?.filter(
        (company) =>
          company.type === COMPANY_TYPE.NOT_CONTROLLED &&
          company?.stage === COMPANY_STAGE.ESG_COMPLETE
      ) || []
    );
  }, [companies]);

  const title = useMemo(() => {
    if (activeRole === USER_ROLE.ANALYST) {
      return 'Evaluated Issuers';
    }
    return 'Issuers';
  }, [activeRole]);

  if (isLoading || !companies) return <PortfolioPageSkeletonLoader />;

  return (
    <PortfolioLayout title={title}>
      <ComposureCharts companies={esgCompleteNotControlledCompanies} />
      <AssessmentSummaryTable
        companies={esgCompleteNotControlledCompanies}
        sorting={sorting}
        setSorting={setSorting}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        prevRouteName='Issuers'
        companiesType={COMPANY_TYPE.NOT_CONTROLLED}
      />
    </PortfolioLayout>
  );
};
