import { useAtom } from 'jotai';
import { activeReportState } from '../../../state/UIState';
import { useMemo } from 'react';
import { DUE_DILIGENCE_SURVEY_NAME } from '../../../constants/constants';
import { useAllSteps } from '../../../hooks/useAllSteps';

export const useWorkflowStepForReport = () => {
  const [report] = useAtom(activeReportState);

  const allSteps = useAllSteps(report?.company?.type);

  const workflowStep = useMemo(() => {
    return allSteps?.find((step) => {
      if (step?.name === DUE_DILIGENCE_SURVEY_NAME) {
        return step?.name === report?.survey?.name;
      }

      return step?.survey?.id === report?.survey?.id;
    });
  }, [allSteps, report?.survey?.id, report?.survey?.name]);

  return workflowStep;
};
