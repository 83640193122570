import { useMemo } from 'react';
import { ChipsSelector } from '../../../components/ButtonSelector/ChipsSelector';
import { FormField } from '../../../components/FormField/FormField';
import { SelectItem } from '../../../types';

interface Props {
  governanceScore?: number | null;
  onChangeGovernanceScore: (val: SelectItem | null) => void;
}

const governanceScoreOptions: SelectItem[] = Array.from({ length: 7 }, (v, k) => k + 1)?.map(
  (item) => ({
    id: String(item),
    value: String(item),
  })
);

export const GovernanceScoreSelector = ({ governanceScore, onChangeGovernanceScore }: Props) => {
  const selectedGovernanceScore = useMemo(
    () => governanceScoreOptions.find((o) => Number(o.id) === Number(governanceScore)) || null,
    [governanceScore]
  );

  return (
    <FormField label='EU Governance Score' style={{ marginBottom: '16px' }}>
      <ChipsSelector
        options={governanceScoreOptions}
        onSelect={(item) => onChangeGovernanceScore(item)}
        selectedItem={selectedGovernanceScore}
        noCheckIcon
        buttonStyles={{ minWidth: 'auto' }}
      />
    </FormField>
  );
};
