import { Fade, IconButton, styled } from '@mui/material';
import { QUESTION_TYPE, QuestionOption } from '../../../../../../types';
import { ReactComponent as DragIcon } from '../../../../../../assets/icons/drag.svg';
import { ReactComponent as CloseIcon } from '../../../../../../assets/icons/close-gray.svg';
import { EditableTextFieldV2 } from '../../../../../../components/EditableTextField/EditableTextFieldV2';
import { Dispatch, SetStateAction } from 'react';
import { FlagOption } from '../../FlagOption';
import { Tooltip } from '../../../../../../components/Tooltip/Tooltip';

const OptionWrapper = styled('div')`
  height: 25px;
  /* padding-right: 8px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TitleWrapper = styled('div')`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
  flex-grow: 1;
`;
const Icon = styled('div')<{ type: QUESTION_TYPE }>`
  height: 13px;
  width: 13px;
  ${({ type }) => type === QUESTION_TYPE.SINGLE && 'border-radius: 50%;'}
  border: 1px solid ${({ theme }) => theme.colors.primary[40]};
`;

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
`;

interface Props {
  onUpdateFlag?: () => void;
  questionType: QUESTION_TYPE;
  showDragIcon: boolean;
  option: QuestionOption;
  onOptionRename: (val: string) => void;
  onDeleteOption: () => void;
  optionTitle: string;
  setOptionTitle: Dispatch<SetStateAction<string>>;
}

export const Option = ({
  onOptionRename,
  showDragIcon,
  option,
  questionType,
  onUpdateFlag,
  onDeleteOption,
  optionTitle,
  setOptionTitle,
}: Props) => {
  return (
    <OptionWrapper>
      <TitleWrapper>
        <DragIcon
          style={{
            opacity: showDragIcon ? '1' : '0',
            margin: '-1px 4px 0 0',
          }}
        />
        <Icon type={questionType} />
        <EditableTextFieldV2
          value={optionTitle}
          onChange={(e) => setOptionTitle(e.target.value)}
          onValueChanged={onOptionRename}
          placeholder='Enter option'
          autoFocus={option.justCreated ? true : false}
        />
      </TitleWrapper>

      {showDragIcon && (
        <Wrapper>
          {onUpdateFlag && <FlagOption isFlagged={option.isFlagged} onToggleFlag={onUpdateFlag} />}
          <Tooltip
            title='Delete this option'
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            PopperProps={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [65, -13],
                  },
                },
              ],
            }}
          >
            <IconButton onClick={onDeleteOption} style={{ marginLeft: '8px', padding: '4px' }}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Wrapper>
      )}
    </OptionWrapper>
  );
};
