import { Table } from '@tanstack/react-table';
import { Multiselect } from '../../../../../components/Multiselect/Multiselect';
import { Company, SelectItem } from '../../../../../types';
import { useAtom } from 'jotai';
import { selectedCompanyAnalystsFilterState } from '../../../../../state/UIState';
import { useCallback } from 'react';

interface Props {
  table: Table<Company>;
  sortedUniqueAnalysts: SelectItem[];
}

export const AnalystFilter = ({ table, sortedUniqueAnalysts }: Props) => {
  const [selectedAnalysts, setSelectedAnalysts] = useAtom(selectedCompanyAnalystsFilterState);

  const onChangeAnalystsFilter = useCallback(
    (analysts: SelectItem[]) => {
      setSelectedAnalysts(analysts);
      table.getColumn('analyst')?.setFilterValue(analysts.map((r) => r.value));
    },
    [setSelectedAnalysts, table]
  );

  return (
    <Multiselect
      style={{ width: '220px' }}
      options={sortedUniqueAnalysts}
      value={selectedAnalysts}
      onChange={(_, val) => {
        onChangeAnalystsFilter(val as SelectItem[]);
      }}
      disablePortal
      optionName='Analyst'
      fieldPlaceholder='Filter by Analysts'
    />
  );
};
