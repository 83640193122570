import { useMutation, useQueryClient } from 'react-query';
import { useLoadingBar } from '../hooks/useLoadingBar';
import { Http } from '../services/Http';
import {
  COMPANY_INFO_STEP,
  LoadingId,
  REQUEST_STEP,
  STEP_TYPE,
  WorkflowStage,
  WorkflowStep,
} from '../types';
import { useToastMessage } from '../hooks/useToastMessage';
import { WORKFLOW_STAGES } from './useWorkflowStages';
import { useAtomValue } from 'jotai';
import { activeWorkflowCompanyType } from '../state/UIState';

export const WORKFLOW_STEPS = 'workflowSteps';

export type WorkflowStepPayload = {
  id?: number | string;
  type: STEP_TYPE;
  survey?: number;
  requestStep?: REQUEST_STEP;
  companyInfoStep?: COMPANY_INFO_STEP;
  accessRoles?: number[];
  isApprovalRequired: boolean;
  approvalRoles?: number[];
  notifyUsers?: number[];
  requiredUsersApproval?: number[];
  blockingSteps?: (number | string)[];
  order?: number;
  stage: number;
  name: string;
  includeBlockingSteps?: boolean;
};

async function createStep(payload: Partial<WorkflowStepPayload>): Promise<WorkflowStep> {
  const { data } = await Http.axios.post<Partial<WorkflowStepPayload>, WorkflowStep>(
    `/workflow-step`,
    payload
  );
  return data;
}

export function useCreateStep() {
  const { startLoading, stopLoading } = useLoadingBar();
  const queryClient = useQueryClient();
  const { pushErrorToast } = useToastMessage();
  const companyType = useAtomValue(activeWorkflowCompanyType);

  return useMutation(createStep, {
    onMutate: async () => {
      startLoading(LoadingId.createStep);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to create step' });
    },
    onSettled: () => {
      queryClient.invalidateQueries([WORKFLOW_STAGES, companyType]);
      stopLoading(LoadingId.createStep);
    },
  });
}

async function updateStep(stage: Partial<WorkflowStepPayload>): Promise<WorkflowStep> {
  const { id, ...payload } = stage;
  const { data } = await Http.axios.patch<Partial<WorkflowStepPayload>, WorkflowStep>(
    `/workflow-step/${id}`,
    payload
  );
  return data;
}

export function useUpdateStep() {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();
  const companyType = useAtomValue(activeWorkflowCompanyType);

  return useMutation(updateStep, {
    onMutate: async () => {
      startLoading(LoadingId.updateStep);
    },
    onError: (error: Error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to update stage' });
    },

    onSettled: () => {
      queryClient.invalidateQueries([WORKFLOW_STAGES, companyType]);
      stopLoading(LoadingId.updateStep);
    },
  });
}

async function updateSteps(payload: {
  data: Partial<WorkflowStepPayload>[];
}): Promise<WorkflowStep[]> {
  const { data } = await Http.axios.patch<{ data: Partial<WorkflowStepPayload>[] }, WorkflowStep[]>(
    `/workflow-step/bulk`,
    payload
  );
  return data;
}

export function useUpdateSteps(stageId: number) {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();
  const companyType = useAtomValue(activeWorkflowCompanyType);

  return useMutation(updateSteps, {
    onMutate: async (payload) => {
      const prevStages = queryClient.getQueryData([
        WORKFLOW_STAGES,
        companyType,
      ]) as WorkflowStage[];

      const sortedSteps = payload.data;

      const updatedStages = prevStages?.map((prevStage) => {
        if (prevStage?.id === stageId) {
          return {
            ...prevStage,
            steps: sortedSteps?.map((sortedStep) =>
              prevStage?.steps?.find((step) => sortedStep.id === step.id)
            ),
          };
        }
        return prevStage;
      });

      queryClient.setQueryData([WORKFLOW_STAGES, companyType], updatedStages);
      startLoading(LoadingId.updateSteps);

      return { prevStages };
    },
    onError: (error: Error, _variables, context) => {
      console.error({ error });
      queryClient.setQueryData([WORKFLOW_STAGES, companyType], context?.prevStages);
      pushErrorToast({ message: 'Failed to update stages' });
    },
    onSettled: () => {
      queryClient.invalidateQueries([WORKFLOW_STAGES, companyType]);
      stopLoading(LoadingId.updateSteps);
    },
  });
}

async function deleteStep(id: string | number): Promise<number> {
  const { data } = await Http.axios.delete<number>(`/workflow-step/${id}`);
  return data;
}

export function useDeleteStep(stageId: number) {
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();
  const queryClient = useQueryClient();
  const companyType = useAtomValue(activeWorkflowCompanyType);

  return useMutation(deleteStep, {
    onMutate: async (id) => {
      const prevStages = queryClient.getQueryData([
        WORKFLOW_STAGES,
        companyType,
      ]) as WorkflowStage[];

      const updatedStages = prevStages?.map((prevStage) => {
        if (prevStage.id === stageId) {
          return {
            ...prevStage,
            steps: prevStage.steps?.filter((step) => step.id !== id),
          };
        }

        return prevStage;
      });

      queryClient.setQueryData([WORKFLOW_STAGES, companyType], updatedStages);
      startLoading(LoadingId.deleteStep);

      return { prevStages };
    },
    onError: (error: Error, _variables, context) => {
      console.error({ error });
      queryClient.setQueryData([WORKFLOW_STAGES, companyType], context?.prevStages);
      pushErrorToast({ message: 'Failed to delete stage' });
    },
    onSettled: () => {
      queryClient.invalidateQueries([WORKFLOW_STAGES, companyType]);
      stopLoading(LoadingId.deleteStep);
    },
  });
}
