import { FC, useMemo } from 'react';
import { useFormik } from 'formik';
import { EsgContactSchema as validationSchema } from './EsgContactSchema';
import { COMPANY_INFO_STEP, Company } from '../../types';
import { CompanyPayload, useUpdateCompany } from '../../queries/useCompanies';
import { AssignEmailModal } from '../AssignEmailModal/AssignEmailModal';
import { useAllSteps } from '../../hooks/useAllSteps';

interface Props {
  onClose: () => void;
  company?: Company;
  isOpen: boolean;
}

export const EsgContactModal: FC<Props> = ({ onClose, company, isOpen }) => {
  const updateCompany = useUpdateCompany();
  const allSteps = useAllSteps(company?.type);

  const stepId = useMemo(
    () => allSteps?.find((step) => step.companyInfoStep === COMPANY_INFO_STEP.ESG_CONTACT)?.id,
    [allSteps]
  );

  const isReassign = useMemo(
    () => Boolean(company?.externalEsgContact?.email),
    [company?.externalEsgContact?.email]
  );

  const {
    handleChange,
    values,
    handleBlur,
    touched,
    errors,
    isValid,
    handleSubmit,
    dirty,
    resetForm,
  } = useFormik<Partial<CompanyPayload>>({
    initialValues: {
      esgContact: '',
    },
    validationSchema,
    onSubmit: (values) => {
      onClose();

      let payload: Partial<CompanyPayload> = {
        id: company?.id,
        esgContact: values.esgContact,
      };

      if (stepId) {
        payload = {
          ...payload,
          completedWorkflowSteps: [...(company?.completedWorkflowSteps || []), stepId],
        };
      }
      updateCompany.mutate(payload);
      resetForm();
    },
  });

  const handleError = (field: keyof CompanyPayload) => (touched[field] ? errors[field] : '');

  return (
    <AssignEmailModal
      isOpen={isOpen}
      title={
        isReassign ? 'Reassign ESG Contact to the Company' : 'Assign ESG Contact to the Company'
      }
      confirmBtnText={isReassign ? 'Reassign' : 'Assign'}
      onClose={onClose}
      isConfirmDisabled={!isValid || !dirty}
      companyName={company?.name || ''}
      companyLogo={company?.logoUrl}
      inputName='esgContact'
      inputLabel={isReassign ? 'New ESG Contact Email' : 'ESG Contact Email'}
      handleChange={handleChange}
      handleBlur={handleBlur}
      handleError={handleError}
      handleSubmit={handleSubmit}
      emailValue={company?.externalEsgContact?.email}
      value={values?.esgContact || ''}
    />
  );
};
