import { styled } from '@mui/material';
import { SearchInput } from '../../../../components/SearchInput/SearchInput';
import { Company, Sector, USER_ROLE } from '../../../../types';
import { Table } from '@tanstack/react-table';
import { TypeFilter } from './components/TypeFilter';
import { StageFilter } from './components/StageFilter';
import { SectorsFilter } from './components/SectorsFilter';
import { CountriesFilter } from './components/CountriesFilter';
import { AnalystFilter } from './components/AnalystFilter';
import { ResetFiltersButton } from '../../../../components/ResetFiltersButton/ResetFiltersButton';
import { useCallback, useEffect, useMemo } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import {
  activeRoleState,
  companiesTableColumnFiltersState,
  selectedCompanyAnalystsFilterState,
  selectedCompanyCountriesFilterState,
  selectedCompanySectorsFilterState,
  selectedCompanyStageFilterState,
  selectedCompanyTypeFilterState,
} from '../../../../state/UIState';
import { useCompanies } from '../../../../queries/useCompanies';

const FiltersWrapper = styled('div')`
  display: flex;
  gap: 12px;
`;

interface Props {
  table: Table<Company>;
}

export const CompanyFilters = ({ table }: Props) => {
  const { data: companies } = useCompanies();
  const [columnFilters, setColumnFilters] = useAtom(companiesTableColumnFiltersState);
  const [, setSelectedSectors] = useAtom(selectedCompanySectorsFilterState);
  const [, setSelectedCountries] = useAtom(selectedCompanyCountriesFilterState);
  const [, setSelectedAnalysts] = useAtom(selectedCompanyAnalystsFilterState);
  const [, setSelectedStage] = useAtom(selectedCompanyStageFilterState);
  const [, setSelectedType] = useAtom(selectedCompanyTypeFilterState);

  const activeRole = useAtomValue(activeRoleState);

  const sortedUniqueSectors = useMemo(() => {
    const allValues = Array.from(table.getColumn('sectors')?.getFacetedUniqueValues().keys() ?? []);
    const map = allValues.reduce((acc, curr) => {
      curr.forEach((element: Sector) => {
        acc[element.name] = element.name;
      });
      return acc;
    }, {});
    const unique =
      Object.keys(map)
        .sort()
        .map((value, id) => ({ id, value })) ?? [];
    return unique;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, companies]);

  const sortedUniqueCountries = useMemo(() => {
    const allValues = Array.from(table.getColumn('country')?.getFacetedUniqueValues().keys() ?? []);
    const map = allValues.reduce((acc, curr) => {
      if (!curr) return acc;
      acc[curr] = curr;
      return acc;
    }, {});

    const unique =
      Object.keys(map)
        .sort()
        .map((value, id) => ({ id, value })) ?? [];
    return unique;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, companies]);

  const sortedUniqueAnalysts = useMemo(() => {
    if (
      activeRole &&
      [USER_ROLE.ANALYST, USER_ROLE.MANAGER, USER_ROLE.COMPLIANCE]?.includes(activeRole)
    )
      return [];
    const allValues = Array.from(table.getColumn('analyst')?.getFacetedUniqueValues().keys() ?? []);
    const unique = allValues.sort().map((value, id) => ({ id, value })) ?? [];
    return unique;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, companies]);

  useEffect(() => {
    if (!columnFilters?.length) {
      setSelectedAnalysts(sortedUniqueAnalysts);
      setSelectedCountries(sortedUniqueCountries);
      setSelectedSectors(sortedUniqueSectors);
    }
  }, [
    columnFilters?.length,
    setSelectedAnalysts,
    setSelectedCountries,
    setSelectedSectors,
    sortedUniqueAnalysts,
    sortedUniqueCountries,
    sortedUniqueSectors,
  ]);

  const onResetFilters = useCallback(() => {
    setSelectedSectors(sortedUniqueSectors);
    setSelectedCountries(sortedUniqueCountries);
    setSelectedAnalysts(sortedUniqueAnalysts);
    setSelectedType(null);
    setSelectedStage(null);
    setColumnFilters([]);
  }, [
    setColumnFilters,
    setSelectedAnalysts,
    setSelectedCountries,
    setSelectedSectors,
    setSelectedStage,
    setSelectedType,
    sortedUniqueAnalysts,
    sortedUniqueCountries,
    sortedUniqueSectors,
  ]);

  const isResetDisabled = useMemo(() => {
    return !columnFilters.length;
  }, [columnFilters.length]);

  if (
    activeRole &&
    [USER_ROLE.ANALYST, USER_ROLE.MANAGER, USER_ROLE.COMPLIANCE]?.includes(activeRole)
  ) {
    return (
      <FiltersWrapper>
        <SearchInput
          placeholder='Search for a company'
          style={{ width: '345px', marginRight: '12px' }}
          onClear={() => table.getColumn('name')?.setFilterValue('')}
          onChange={(e) => table.getColumn('name')?.setFilterValue(e.target.value)}
          value={table.getColumn('name')?.getFilterValue() ?? ''}
        />
        <SectorsFilter table={table} sortedUniqueSectors={sortedUniqueSectors} />
        <ResetFiltersButton onResetFilters={onResetFilters} isResetDisabled={isResetDisabled} />
      </FiltersWrapper>
    );
  }

  return (
    <FiltersWrapper>
      <SearchInput
        placeholder='Search for a company'
        style={{ width: '345px', marginRight: '12px' }}
        onClear={() => table.getColumn('name')?.setFilterValue('')}
        onChange={(e) => table.getColumn('name')?.setFilterValue(e.target.value)}
        value={table.getColumn('name')?.getFilterValue() ?? ''}
      />
      <TypeFilter table={table} />
      <StageFilter table={table} />
      <SectorsFilter table={table} sortedUniqueSectors={sortedUniqueSectors} />
      <CountriesFilter table={table} sortedUniqueCountries={sortedUniqueCountries} />
      <AnalystFilter table={table} sortedUniqueAnalysts={sortedUniqueAnalysts} />
      <ResetFiltersButton onResetFilters={onResetFilters} isResetDisabled={isResetDisabled} />
    </FiltersWrapper>
  );
};
