import { ExportCompanyColumn, ExportCompanyExclusionsColumn } from '../../../types';

export const companyColumns: ExportCompanyColumn[] = [
  {
    column: 'Type',
    value: 'type',
  },
  {
    column: 'Stage',
    value: 'stage',
  },
  {
    column: 'SASB Sector',
    value: 'sectors',
  },
  {
    column: 'SASB Industry',
    value: 'industry',
  },
  {
    column: 'Country',
    value: 'country',
  },
  {
    column: 'Assigned ESG contact',
    value: 'externalEsgContact',
  },
  {
    column: 'Assigned analyst',
    value: 'analyst',
  },
  {
    column: 'ESG Risk Level',
    value: 'esgRiskLevel',
  },
  {
    column: 'ESG Opportunity Level',
    value: 'esgOpportunitiesLevel',
  },
  {
    column: 'Questionnaire flags',
    value: 'reports',
  },
];

export const exclusionColumns: ExportCompanyExclusionsColumn[] = [
  {
    column: 'Exclusion Category',
    value: 'category',
  },
  {
    column: 'Condition',
    value: 'condition',
  },
  {
    column: 'Investor Exclusion',
    value: 'text',
  },
  {
    column: 'Status',
    value: 'status',
  },
  {
    column: 'Investor Name',
    value: 'investorName',
  },
  {
    column: 'Fund',
    value: 'funds',
  },
  {
    column: 'Last Updated',
    value: 'updated_at',
  },
];
