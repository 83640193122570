import { styled } from '@mui/material';
import { FormField } from '../FormField/FormField';
import { Modal } from '../Modal/Modal';
import { SingleSelect } from '../SingleSelect/SingleSelect';
import { Textarea } from '../Textarea/Textarea';
import { LocationAutocomplete } from './LocationAutocomplete';
import { useParams } from 'react-router';
import { CompanyPayload, useCompanyById, useUpdateCompany } from '../../queries/useCompanies';
import { useCallback, useMemo, useState } from 'react';
import { COMPANY_INFO_STEP, SelectItem } from '../../types';
import { useFormik } from 'formik';
import { CompanySchema as validationSchema } from '../../pages/SIngleCompany/schemas/CompanySchema';
import { useSectors } from '../../queries/useSectors';
import { useAllSteps } from '../../hooks/useAllSteps';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const FormItemWrapper = styled('div')`
  margin-top: 16px;
`;

export const EditCompanyModal = ({ isOpen, onClose }: Props) => {
  const params = useParams();
  const { data: company } = useCompanyById(Number(params.id));
  const { data: sectors } = useSectors();
  const updateCompany = useUpdateCompany();

  const allSteps = useAllSteps(company?.type);

  const stepId = useMemo(
    () =>
      allSteps?.find((step) => step.companyInfoStep === COMPANY_INFO_STEP.COMPANY_INFORMATION)?.id,
    [allSteps]
  );

  const [industryOptions, setIndustryOptions] = useState<SelectItem[]>(() => {
    if (!company || !company?.sectors?.length) return [];
    return company.sectors[0].industries.map((i) => ({ id: i.id, value: i.name }));
  });

  const sectorOptions = useMemo(() => {
    if (!sectors) return [];
    return sectors.map((s) => ({ id: s.id, value: s.name }));
  }, [sectors]);

  const [sector, setSector] = useState<SelectItem | null>(() => {
    if (!company || !company?.sectors?.length) return null;
    const { id, name } = company.sectors[0];
    return { id, value: name };
  });

  const [industry, setIndustry] = useState<SelectItem | null>(() => {
    if (!company || !company?.industry) return null;
    const { id, name } = company.industry;
    return { id, value: name };
  });

  const [location, setLocation] = useState<SelectItem | null>(() => {
    if (!company || (!company.city && !company.country)) return null;
    const optionValue =
      company.city && company.country
        ? `${company.city}, ${company.country}`
        : company.city || company.country;
    return { id: optionValue, value: optionValue };
  });

  // useEffect(() => {
  //   if (!company || !company?.sectors?.length) return;
  //   const { id, name } = company.sectors[0];
  //   setSector({ id, value: name });
  // }, [company]);

  const {
    handleChange,
    values,
    handleBlur,
    touched,
    errors,
    setFieldValue,
    isValid,
    handleSubmit,
    dirty,
  } = useFormik<Partial<CompanyPayload>>({
    initialValues: {
      sectors: company?.sectors && company?.sectors.length ? company?.sectors.map((s) => s.id) : [],
      industry: company?.industry?.id ?? null,
      description: company?.description ?? '',
      city: company?.city ?? '',
      country: company?.country ?? '',
    },
    validationSchema,
    onSubmit: (values) => {
      let payload = { id: Number(params.id), ...values };

      if (stepId) {
        payload = {
          ...payload,
          completedWorkflowSteps: [...(company?.completedWorkflowSteps || []), stepId],
        };
      }

      updateCompany.mutate(payload);
      onClose();
    },
  });

  const handleError = (field: keyof CompanyPayload) => (touched[field] ? errors[field] : '');

  const onChangeLocation = useCallback(
    (newLocation: SelectItem | null) => {
      if (!newLocation) {
        setFieldValue('city', '');
        setFieldValue('country', '');
      }
      if (newLocation?.value) {
        const arr = newLocation.value.split(', ');
        const city = arr[0];
        const country = arr.length === 2 ? arr[1] : arr[arr.length - 1];
        setFieldValue('city', city);
        setFieldValue('country', country);
      }
      setLocation(newLocation);
    },
    [setFieldValue]
  );

  const onChangeSector = useCallback(
    (newSector: SelectItem | null) => {
      if (!newSector) {
        setFieldValue('sectors', []);
        return;
      }
      setFieldValue('sectors', [newSector.id]);
      setSector(newSector);
      setFieldValue('industry', null);
      setIndustry(null);
      const industryOptions = sectors?.find((s) => s.id === newSector.id)?.industries;
      if (!industryOptions) return;
      setIndustryOptions(
        industryOptions.map((i) => ({
          id: i.id,
          value: i.name,
        }))
      );
    },
    [sectors, setFieldValue]
  );

  const onChangeIndustry = useCallback(
    (newIndustry: SelectItem | null) => {
      if (!newIndustry) {
        setFieldValue('industry', null);
        return;
      }
      setFieldValue('industry', newIndustry.id);
      setIndustry(newIndustry);
    },
    [setFieldValue]
  );

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      title='Edit Company Information'
      onConfirm={handleSubmit}
      cancelBtnText='Cancel'
      confirmBtnText='Save Changes'
      isConfirmDisabled={!isValid || !dirty}
    >
      <FormItemWrapper>
        <FormField label='SASB sector' error={handleError('sectors')}>
          <SingleSelect
            options={sectorOptions}
            value={sector}
            onChange={(e, item) => onChangeSector(item)}
            disablePortal
            fieldPlaceholder='Select sector'
          />
        </FormField>
      </FormItemWrapper>
      <FormItemWrapper>
        <FormField label='SASB industry' error={handleError('industry')}>
          <SingleSelect
            options={industryOptions}
            value={industry}
            onChange={(e, item) => onChangeIndustry(item)}
            disablePortal
            fieldPlaceholder='Select industry'
          />
        </FormField>
      </FormItemWrapper>
      <FormItemWrapper>
        <FormField label='HQ location'>
          <LocationAutocomplete location={location} setLocation={onChangeLocation} />
        </FormField>
      </FormItemWrapper>
      <FormItemWrapper>
        <FormField label='Description'>
          <Textarea
            style={{ height: '100px' }}
            name='description'
            value={values.description ?? ''}
            onChange={handleChange('description')}
            placeholder='Description'
            maxLength={800}
            onBlur={handleBlur}
          />
        </FormField>
      </FormItemWrapper>
    </Modal>
  );
};
