import { Button } from '@mui/material';
import { useAtom } from 'jotai';
import { MouseEvent, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { exportColumnsState, exportCompaniesState, exportTypeState } from '../../../state/UIState';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { ROUTES } from '../../../constants/routes';
import { CSVLink } from 'react-csv';
import { useResetExportCompaniesState } from '../hooks/useResetExportCompaniesState';
import { useExternalInvestors } from '../../../queries/useInvestors';
import {
  COMPANY_TYPE,
  CompanyExclusion,
  EXPORT_COMPANIES_TYPE,
  ExportCompanyColumn,
  ExportCompanyExclusionsColumn,
  Report,
  STEP_TYPE,
} from '../../../types';
import { getExclusionsData } from './getExclusionsData';
import { getFormattedCompanyExclusions } from '../../../utils/getFormattedCompanyExclusions';
import { getCompanyAssessmentReports } from '../../../utils/getCompanyAssessmentReports';
import { getNumberOfFlaggedAnswers } from '../../../utils/getNumberOfFlaggedAnswers';
import { useAllSteps } from '../../../hooks/useAllSteps';

type CompanyDetailsData = {
  detailLabel: string;
} & Record<string, string>;

type ReportFlagsData = {
  detailLabel: string;
  companyId: string;
  flags: string;
};

const getAssessmentFlags = (assessmentReport?: Report) => {
  if (!assessmentReport) return 0;
  return getNumberOfFlaggedAnswers(assessmentReport) || 0;
};

export const ControlButtons = () => {
  const params = useParams();
  const activeStep = Number(params.activeStep);
  const [selectedColumns] = useAtom(exportColumnsState);
  const [selectedCompanies] = useAtom(exportCompaniesState);
  const navigate = useNavigate();
  const [exportType] = useAtom(exportTypeState);

  const { navigateAndPreserveParams } = useQueryParams();

  const { data: investors } = useExternalInvestors();

  const allNotControlledSteps = useAllSteps(COMPANY_TYPE.NOT_CONTROLLED);
  const allControlledSteps = useAllSteps(COMPANY_TYPE.CONTROLLED);

  const allUniqueAssessmentSteps = useMemo(() => {
    const allSteps = [...(allControlledSteps || []), ...(allNotControlledSteps || [])]?.filter(
      (step) => step?.type === STEP_TYPE.ASSESSMENT
    );

    return [...new Map(allSteps.map((item) => [item.id, item])).values()];
  }, [allControlledSteps, allNotControlledSteps]);

  const resetState = useResetExportCompaniesState();

  const onNext = useCallback(async () => {
    navigateAndPreserveParams(`/${ROUTES.EXPORT_COMPANIES}/${2}`);
  }, [navigateAndPreserveParams]);

  const formattedDetails: CompanyDetailsData[] = useMemo(() => {
    const selectedRows = selectedColumns as ExportCompanyColumn[];

    const reportFlags: ReportFlagsData[][] = [];

    const details = selectedRows?.map((row) => {
      let detail: CompanyDetailsData | null = {
        detailLabel: row.column,
      };

      selectedCompanies?.forEach((company) => {
        if (!company) return;
        let value = company[row.value];

        if (row?.value === 'sectors') {
          value = company[row?.value]?.[0]?.name;
        }

        if (row?.value === 'industry') {
          value = company[row?.value]?.name;
        }

        if (row?.value === 'externalEsgContact') {
          value = company[row?.value]?.email;
        }

        if (row.value === 'reports') {
          const assessmentReports = getCompanyAssessmentReports(company, allUniqueAssessmentSteps);

          const companyReportFlags = assessmentReports?.map((report) => {
            return {
              detailLabel: report?.survey?.name,
              companyId: String(company?.id),
              flags: `${getAssessmentFlags(report)} flags`,
            };
          });

          reportFlags.push(companyReportFlags);

          detail = null;
          return;
        }
        detail = {
          ...detail,
          [company.id]: value || '',
        } as CompanyDetailsData;
      });
      return detail;
    });

    const reportsFlat = reportFlags?.flatMap((r) => r);

    const uniqueReports = [...new Set(reportsFlat?.map((r) => r.detailLabel))];

    const reportDetails: CompanyDetailsData[] = uniqueReports?.map((report) => {
      let rD = {
        detailLabel: report,
      };

      reportsFlat.forEach((r) => {
        if (r.detailLabel === report) {
          rD = {
            ...rD,
            [r.companyId]: r.flags,
          };
        }
      });

      return rD;
    });

    return [...details, ...reportDetails]?.filter((d) => Boolean(d));
  }, [allUniqueAssessmentSteps, selectedColumns, selectedCompanies]);

  const formattedExclusions: (CompanyExclusion & { name: string })[] = useMemo(() => {
    if (!selectedCompanies?.length) return [];

    return selectedCompanies
      ?.map((comp) => {
        const sortedExclusions = comp.exclusions?.sort((a, b) =>
          a?.condition?.category?.name?.localeCompare(b?.condition?.category?.name)
        );
        const compExclusions = getFormattedCompanyExclusions(sortedExclusions, comp, investors);
        return getExclusionsData(
          comp.name,
          compExclusions,
          selectedColumns as ExportCompanyExclusionsColumn[]
        );
      })
      ?.flatMap((data) => data);
  }, [investors, selectedColumns, selectedCompanies]);

  const isExportDisabled = useMemo(
    () => !selectedCompanies?.length || !selectedColumns?.length,
    [selectedColumns?.length, selectedCompanies?.length]
  );

  const detailsHeaders = useMemo(() => {
    const headerCols = selectedCompanies?.map((company) => {
      return { label: company?.name, key: String(company.id) };
    });
    return [{ label: '', key: 'detailLabel' }, ...headerCols];
  }, [selectedCompanies]);

  const exclusionsHeaders = useMemo(() => {
    const headerCols = selectedColumns?.map((col) => ({ label: col.column, key: col.value }));

    const nameColLabel =
      exportType === EXPORT_COMPANIES_TYPE.companyDetails ? 'Name' : 'Investment Name';

    return [{ label: nameColLabel, key: 'name' }, ...headerCols];
  }, [exportType, selectedColumns]);

  const headers = useMemo(() => {
    if (exportType === EXPORT_COMPANIES_TYPE.companyDetails) return detailsHeaders;

    return exclusionsHeaders;
  }, [detailsHeaders, exclusionsHeaders, exportType]);

  const exportData = useMemo(() => {
    if (exportType === EXPORT_COMPANIES_TYPE.companyDetails) return formattedDetails;

    return formattedExclusions;
  }, [exportType, formattedDetails, formattedExclusions]);

  const fileName = useMemo(() => {
    if (exportType === EXPORT_COMPANIES_TYPE.companyExclusions) return 'Exclusions';

    return 'Companies';
  }, [exportType]);

  const onCancel = useCallback(() => {
    resetState();
    navigate(`/${ROUTES.COMPANIES}`);
  }, [navigate, resetState]);

  const onClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      const button = document.getElementById('companies-export');
      if (button) {
        button.click();
      }
      resetState();
      navigate(`/${ROUTES.COMPANIES}`);
    },
    [navigate, resetState]
  );

  return (
    <>
      <Button variant='outlined' style={{ width: '95px' }} onClick={onCancel}>
        Cancel
      </Button>
      {activeStep === 1 && (
        <Button
          variant='contained'
          style={{ width: '95px' }}
          disabled={!Object.keys(selectedColumns)?.length}
          onClick={onNext}
        >
          Next
        </Button>
      )}
      {activeStep === 2 && (
        <>
          <Button
            variant='contained'
            style={{ width: '95px' }}
            disabled={isExportDisabled}
            onClick={onClick}
          >
            Export
          </Button>
          <CSVLink headers={headers} data={exportData} filename={fileName} separator={';'}>
            <Button
              variant='text'
              id={`companies-export`}
              style={{ visibility: 'hidden', width: '0', position: 'absolute' }}
            />
          </CSVLink>
        </>
      )}
    </>
  );
};
