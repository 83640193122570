import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import {
  esgGovernanceScoreOverrideState,
  esgOpportunitiesLevelOverrideState,
  esgRiskLevelOverrideState,
  selectedReviewOptionState,
} from '../../../../../state/UIState';
import { REVIEW_TYPE } from '../../../../../types';

export const useIsAnalystRatingReviewed = () => {
  const selectedReviewOption = useAtomValue(selectedReviewOptionState);
  const esgRiskLevelOverride = useAtomValue(esgRiskLevelOverrideState);
  const esgOpportunitiesLevelOverride = useAtomValue(esgOpportunitiesLevelOverrideState);
  const esgGovernanceScoreOverride = useAtomValue(esgGovernanceScoreOverrideState);

  return useMemo(() => {
    if (selectedReviewOption === REVIEW_TYPE.OVERRIDE) {
      return Boolean(
        esgRiskLevelOverride || esgOpportunitiesLevelOverride || esgGovernanceScoreOverride
      );
    }

    return Boolean(selectedReviewOption);
  }, [
    esgGovernanceScoreOverride,
    esgOpportunitiesLevelOverride,
    esgRiskLevelOverride,
    selectedReviewOption,
  ]);
};
