import { Button, styled, useTheme } from '@mui/material';
import { REPORT_STATUS, Report } from '../../../types';
import { RequestStatusCell } from '../../Table/CellRenderers/RequestStatusCell';
import { getNumberOfFlaggedAnswers } from '../../../utils/getNumberOfFlaggedAnswers';
import { FlagsIndicator } from '../../FlagsIndicator/FlagsIndicator';

import { useReportStatusIndicator } from '../../../pages/SIngleCompany/hooks/useReportStatusIndicator';
import { getAvgEvaluationScore } from '../../../utils/getAvgEvaluationScore';
import { EvaluationScorePreview } from '../../EvaluationScorePreview/EvaluationScorePreview';

const Container = styled('div')`
  display: flex;
  align-items: center;
`;

const Wrapper = styled('div')`
  margin-right: 4px;
`;
interface Props {
  report?: Report;
  onCreateReport?: () => void;
  buttonText: string;
  disabled?: boolean;
}

export const TaskReportIndicator = ({ report, onCreateReport, buttonText, disabled }: Props) => {
  const { colors } = useTheme();

  const numOfFlags = getNumberOfFlaggedAnswers(report);
  const status = useReportStatusIndicator(report);
  const avgEvalScore = getAvgEvaluationScore(report);

  const evaluationRules = report?.survey.evaluationRules;
  const avgRule = evaluationRules?.find((rule) => rule.weight === avgEvalScore);

  if (report) {
    return (
      <Container>
        {avgRule && (
          <Wrapper>
            <EvaluationScorePreview
              color={colors[avgRule.color][40]}
              label={avgRule.name}
              evaluationScale={report.survey.evaluationScale}
              score={avgRule.weight}
            />
          </Wrapper>
        )}
        <Wrapper>
          <FlagsIndicator flagsNum={numOfFlags || 0} />
        </Wrapper>

        <RequestStatusCell status={status as REPORT_STATUS} />
      </Container>
    );
  }

  return (
    <Button
      onClick={onCreateReport}
      variant='contained'
      style={{ backgroundColor: disabled ? colors.accent[20] : colors.accent[50] }}
      disabled={disabled}
    >
      {buttonText}
    </Button>
  );
};
